export default {
  mobileFirefoxAlert: 'Тапсырманы өңдеу бұл шолғышта қол жетімді емес',
  datePicker: {
    from: 'Қайдан',
    to: 'Бұрын',
    cancel: 'Болдырмау',
    apply: 'Өтініш',
    month: [
      'Қаңтар',
      'Ақпан',
      'Наурыз',
      'Сәуір',
      'Мамыр',
      'Маусым',
      'Шілде',
      'Тамыз',
      'Қыркүйек',
      'Қазан',
      'Қараша',
      'Желтоқсан'
    ],
    week: 'апта',
    weekShot: 'апта',
    buttonName: {
      date: 'Күні',
      week: 'Апта',
      month: 'Ай'
    },
    modal: {
      header: 'Назар аударыңыз!',
      text: 'Жарамсыз күндер ауқымы таңдалды',
      action: 'ОК'
    }
  },
  filters: {
    name: 'Аты'
  },
  header: {
    logout: 'Шығу',
    changePassword: 'Құпия сөзді өзгерту',
    settings: 'Параметрлер',
    title: 'Посмотреть интерфейс пользователя',
    searchTitle: 'Поиск пользователя',
    input: 'Начните вводить имя или логин…',
    cancel: 'Отмена',
    view: 'Просмотр',
    id: 'ID',
    login: 'Логин',
    name: 'Имя',
    phone: 'Телефон',
    email: 'Почта',
    stopSettings: 'Остановить просмотр'
  },
  scenario: {
    noDataReceived: 'Деректер алынған жоқ',
    filterPart: {
      name: 'Аты',
      clear: 'Таза',
      notFound: 'Табылмады',
      search: 'Іздеу...'
    }
  },
  directTask: {
    addCase: 'Іс қосу',
    addComment: 'Түсініктеме',
    actions: 'Әрекеттер',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Фото',
    back: 'Оралу',
    treeEmpty: 'БОЛМАЙТЫН ӘРЕКЕТТЕР',
    step: 'Қадам',
    sortingButton: 'Сұрыптау',
    sortButton: 'Сұрыптау',
    add: 'Қосу',
    delete: 'Жою',
    deleteManualCases: 'Істі жою',
    show: 'Көрсету',
    hide: 'Жасыру',
    comment: 'Түсініктеме',
    showDoneCases: 'Қолданылған жағдайларды көрсету',
    hideDoneCases: 'Қолданылған жағдайларды жасыру'
  },
  gridTable: {
    pluList: 'Тізім PLU'
  },
  casesForm: {
    product: 'Өнім',
    loadFoto: 'Фотосуретті жүктеңіз',
    plu: 'Тақырыпты енгізіңіз/PLU',
    success: 'Іс жасалды',
    add: 'құру',
    new: 'Жаңа',
    delete: 'Өшіру',
    cancel: 'Болдырмау',
    comment: 'Түсініктеме'
  },
  pagination: {
    apply: 'Алға',
    placeholder: 'бөлме'
  },
  restorePass: {
    noLength: 'Пароль 12 таңбадан аз',
    noLowercase: 'Кем дегенде бір кіші әріпті қажет етеді',
    noUppercase: 'Требуется как минимум одна прописная буква',
    noNumber: 'Кем дегенде бір бас әріп қажет',
    noMatch: 'Құпия сөздер сәйкес келмейді',
    password: 'құпиясөз',
    repeatPassword: 'Құпия сөзді қайталаңыз',
    ok: 'Растаңыз',
    cancel: 'Болдырмау'
  },
  navigation: {
    missions: 'Миссиялар',
    scenarios: 'Сценарийлер'
  },
  changePassword: {
    previousPassword: 'Алдыңғы пароль',
    newPassword: 'Жаңа пароль',
    confirmPassword: 'Жаңа құпия сөз',
    apply: 'Өтініш',
    title: 'Құпия сөзді өзгерту',
    success: 'Құпия сөз сәтті өзгертілді',
    wrongPassword: 'Құпия сөз дұрыс емес',
    weakPassword: 'Құпия сөз өлшемдерге сәйкес келмейді',
    passwordsNotMatch: 'Құпия сөздер сәйкес келмейді',
    requirements:
      'Құпия сөз кемінде 12 таңбадан тұруы керек, оның ішінде бір бас әріп, бір бас әріп және бір сан '
  },
  auth: {
    login: 'Логин',
    password: 'құпиясөз',
    restorePass: 'Құпия сөзді қалпына келтіріңіз',
    submit: 'Жүйеге кіру',
    loginError: 'Логин немесе пароль дұрыс емес'
  },
  restorePassForm: {
    restore: 'Қалпына келтіру',
    cancel: 'Болдырмау',
    info: 'GROSA платформасында тіркелген электрондық поштаңызды енгізіңіз',
    emailInvalid: 'Электрондық пошта табылмады',
    email: 'Email',
    success: 'Құпия сөзді қалпына келтіру сілтемесі электрондық поштаңызға жіберілді'
  },
  search: {
    notFound: 'Табылмады'
  },
  noMatchPage: {
    title: 'Сұралған бет табылмады',
    body: 'Басты бетке оралу'
  },
  warnings: {
    title: 'Назар аударыңыз!',
    isDesktop:
      'Фотосуреттерді жүктеу тек жүйенің мобильді нұсқасында қол жетімді. Телефонды немесе планшеттік камераны пайдаланыңыз.'
  },
  errors: {
    netErrorText: 'Желі қатесі. Бетті қайта жүктеңіз',
    netErrorReload: 'Қайта жүктеңіз',
    netErrorClose: 'Жабық',
    netErrorTitle: 'Қате',
    noShops: 'О! Сізге әлі дүкен тағайындалмаған сияқты. Бұл туралы менеджеріңізге айтыңыз.',
    noCases:
      'Біздің қызмет бұл дүкеннен ешқандай жағдай таба алмаған сияқты. Оларды өзіңіз жасай бастаңыз.',
    noAutoOrder: 'ADR түзетуге арналған тапсырмалар жоқ.',
    causeError: 'Қате',
    scenariosEmpty: 'Сізде бір сценарий жоқ'
  },
  officeUI: {
    resetZoom: 'Масштабты қалпына келтіру'
  },
  gridAutocomplete: {
    placeholder: 'Дүкен атауын теруді бастаңыз',
    notFound: 'Дүкен табылмады'
  },
  targets: {
    nothing: '',
    shop: 'Гол',
    category: 'Санат',
    supplier: 'Провайдер',
    vendor: 'сатушы',
    brand: 'Тауар атауы',
    plu: 'PLU',
    day: 'күн',
    week: 'апта',
    month: 'Ай',
    quarter: 'тоқсан',
    year: 'жыл'
  },
  bubbleChart: {
    qty: 'дана',
    currency: 'тенге'
  },
  chart: {
    quarter: 'тоқсан',
    week: 'апта'
  },
  download: {
    CSV: 'Жүктеу CSV',
    XLS: 'Жүктеу XLS'
  },
  counterTask: {
    task: 'Тапсырмалар бар',
    noTask: 'Тапсырмалар жоқ'
  },
  filtersShop: {
    shopName: 'Дүкеннің мекен-жайы',
    shopId: 'Дүкеннің атауы'
  },
  chartCaption: {
    thousand: 'мың',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Жабық',
    userNotFound: 'Пайдаланушы табылмады',
    yes: 'Иә',
    no: 'Жоқ',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "Internal PLU",
      shop_id: "Shop ID",
      ass_type: "Assortment Type",
      category_id3: "Section ID",
      category_id4: "Category ID",
      stock: "Volume of residues",
      stock_sum: "Amount of balances",
      product_turnover: "Turnover",
      days_without_sales: "Days without sales",
      last_sales_date: "Last sale date",
      last_sales_volume: "Last sale volume",
      last_shipment_date: "Last shipment date",
      last_shipment_volume: "Last shipment volume",
      promo_start: "Promo Start",
      promo_end: "Promo End",
      promo_period: "Promo Period",
      is_promo: "Promo",
      title: "Name",
      category_name3: "Section",
      category_name4: "Category"
    },
    hideReadyTasks: "Дайын тапсырмаларды жасыру",
    nothingFound: "Ештеңе табылмады",
    shopsNotFound: "Дүкендер табылмады",
    chooseShop: "Дүкен таңдаңыз",
    createTasks: "Тапсырмалар жасау",
    taskCreating: "Тапсырмаларды құру",
    next: "Жалғастыру",
    columns: "Кесте бағандары",
    from: "/",
    ok: "OK",
    cancel: "Болдырмау",
    filter: "Сүзгі",
    chooseValue: "Мәнді таңдаңыз",
    enterValue: "Мән енгізіңіз",
    rangeFrom: "Ең төменгі мән",
    rangeTo: "Максималды мән",
    selected: "Таңдалған",
    foundRows: "Жазбалар табылды",
    createdTasksTotal: "Жасалған тапсырмалардың жалпы саны"
  }
};
