import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';
import has from 'lodash/has';
import moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { setDatePickerAction } from '../../store/actions/actions';
import DatePickerDialog from './DatePickerDialog';

import styles from './DatePickerComponent.css';

const browserLanguage = navigator.language.split('-')[0];
moment.locale(browserLanguage);

const materialTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    fontFamily: ['MuseoSansRounded', 'sans-serif', '-apple-system'].join(','),
    fontWeight: 400,
    htmlFontSize: 16
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: 0
      },
      root: {
        textTransform: 'none',
        color: '#fff',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }
      }
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomWidth: 0
        },
        '&:hover:not($disabled):before': {
          borderBottomWidth: 0
        },
        '&:hover:after': {
          borderBottomWidth: 0
        },
        '&:after': {
          borderBottomWidth: 0
        },
        '&:before': {
          borderBottomWidth: 0
        },
        '&:focus': {
          borderBottomWidth: 0
        }
      },
      input: {
        // fontSize: '1.3rem',
        color: '#fff',
        cursor: 'pointer'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        ['@media (max-width: 767px)']: {
          marginLeft: 20
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
      h4: {
        ['@media (max-width: 767px)']: {
          // fontSize: '1.6rem'
        }
      },
      subtitle1: {
        ['@media (max-width: 767px)']: {
          marginLeft: '0 !important'
        }
      }
    },
    MuiPickersBasePicker: {
      container: {
        opacity: 0.6,
        transition: '0.2s',
        '&:hover': {
          opacity: 1
        },
        '&:focus': {
          opacity: 1
        }
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        ['@media (max-width: 767px)']: {
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0284b0',
        position: 'relative',
        ['@media (max-width: 767px)']: {
          // height: '56px'
        }
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#398bdf',
        '&:hover': {
          backgroundColor: '#0077a7'
        }
      }
    }
  }
});

const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    const {
      data: {
        data: { startDate, endDate }
      }
    } = this.props;

    this.state = {
      minDate: new Date(startDate),
      maxDate: new Date(endDate),
      prevMinDate: new Date(startDate),
      prevMaxDate: new Date(endDate),
      isOpenAlert: false,
      setDateByUser : false
    };
  }

  componentDidMount() {
    this.setDate(false);
  }

  componentWillReceiveProps(nextProps) {
    const {
      data: {
        data: { startDate, endDate }
      }
    } = this.props;

    if (has(nextProps, 'filters.date_start') && has(nextProps, 'filters.date_end')) {
      const { date_start, date_end } = nextProps.filters;
      if (startDate !== date_start || endDate !== date_end) {
        if(is_safari) {
          this.setState({
            minDate: new Date(date_start.replace(/-/g, "/")),
            maxDate: new Date(date_end.replace(/-/g, "/")),
            prevMinDate: new Date(date_start),
            prevMaxDate: new Date(date_end)
          });
        } else {
          this.setState({
            minDate: new Date(date_start),
            maxDate: new Date(date_end),
            prevMinDate: new Date(date_start),
            prevMaxDate: new Date(date_end)
          });
        }
      }
    }
  }

  handleClickDate = e => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    const { prevMinDate, prevMaxDate } = this.state;
    this.setState({
      anchorEl: null,
      minDate: prevMinDate,
      maxDate: prevMaxDate
    });
  };

  handleModalClose = () => {
    this.setState({ isOpenAlert: false });
  };

  setDate = update => {
    const { filters, setDrillFilters, updateFilters } = this.props;
    const { minDate, maxDate } = this.state;
    if (minDate && maxDate) {
      const startDate = new Date(minDate);
      const endDate = new Date(maxDate);
      const scenarioFilters = {
        ...filters,
        date_start: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
        date_end: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
      };

      setDrillFilters(scenarioFilters);
      if (update) {
        updateFilters(scenarioFilters, 'date');
      }
    }
  };

  formatDate = formatDate => {
    const date = new Date(formatDate);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    return day + '.' + month + '.' + date.getFullYear();
  };

  handleSetDate = () => {
    const { minDate, maxDate } = this.state;
    const { dispatch } = this.props;

    if (minDate.getTime() <= maxDate.getTime()) {
      dispatch(
        setDatePickerAction({
          minDate: minDate,
          maxDate: maxDate
        })
      );
      this.setDate(true);
      this.setState({
        prevMinDate: minDate,
        prevMaxDate: maxDate,
        anchorEl: null
      });
    } else {
      this.setState({
        isOpenAlert: true
      });
    }
  };

  handleChangeMinDate = date => {
    this.setState({
      setDateByUser: true,
      minDate: date.toDate()
    });
  };

  handleChangeMaxDate = date => {    
    this.setState({
      setDateByUser: true,
      maxDate: date.toDate()
    });
  };

  render() {
    const {
      data: {
        data: { historicDate },
      },
      language: {
        lang = 'en',
        translation: {
          datePicker,
          datePicker: { modal = {} }
        }
      }
    } = this.props;

    const { minDate, maxDate, anchorEl, isOpenAlert, setDateByUser } = this.state;    

    let dateMin = new Date('1900-01-01');
    if(historicDate){
      dateMin = new Date(historicDate);
    }
    // const dateMinTo = new Date(historicDate || startDate);
    
    let mainMinDate = minDate;
    let mainMaxDate = maxDate;
    
    const filters = this.props.filters;
    if(Object.keys(filters).length !== 0){
      const { date_start, date_end } = filters;
      if(date_start && date_end && !setDateByUser){
        if(is_safari) {
          mainMinDate = (minDate !== date_start) ? new Date(date_start.replace(/-/g, "/")) : minDate;
          mainMaxDate = (maxDate !== date_end) ? new Date(date_end.replace(/-/g, "/")) : maxDate;
        } else {
          mainMinDate = (minDate !== date_start) ? date_start : minDate;
          mainMaxDate = (maxDate !== date_end) ? date_end : maxDate;
        }
      }
    }
    return (
      <MuiThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={lang} moment={moment}>
          <div className={styles.container}>
            <Grid fluid class={styles.datePickerWrap}>
              <Row middle="md" class={styles.datePickerRow}>
                <Col md={3} class={styles.iconToWrap}>
                  <DateRangeIcon style={{ color: '#fff' }} />
                </Col>
                <Col md={9} class={styles.fromToWrap}>
                  <Row middle="md" class={styles.datePickerRow}>
                    <Col md={6}>{datePicker.from}</Col>
                    <Col md={6}>{datePicker.to}</Col>
                  </Row>
                  <Row middle="md" class={cls(styles.datePickerRow, styles.dateText)} onClick={this.handleClickDate}>
                    <Col md={6}>{moment(mainMinDate).format('DD.MM.YYYY')}</Col>
                    <Col md={6}>{moment(mainMaxDate).format('DD.MM.YYYY')}</Col>
                  </Row>
                </Col>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <div className={styles.pickersPopoverContainer}>
                    <div className={styles.pickersContainer}>
                      <DatePicker
                        disableFuture
                        orientation="portrait"
                        variant="static"
                        emptyLabel={datePicker.from}
                        minDate={dateMin}
                        onChange={this.handleChangeMinDate}
                        value={new Date(mainMinDate)}
                      />
                      <DatePicker
                        disableFuture
                        orientation="portrait"
                        variant="static"
                        emptyLabel={datePicker.to}
                        minDate={dateMin}
                        onChange={this.handleChangeMaxDate}
                        value={new Date(mainMaxDate)}
                      />
                    </div>
                    <div className={styles.pickersContols}>
                      <Button color="primary" variant="outlined" onClick={this.handleSetDate}>
                        {datePicker.apply}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </Row>
            </Grid>
            <DatePickerDialog translation={modal} open={isOpenAlert} handleClose={this.handleModalClose} />
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

DatePickerComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  setDrillFilters: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(store => {
  return {
    language: store.language
  };
})(DatePickerComponent);
