export default {
  mobileFirefoxAlert: 'V tem brskalniku obdelava nalog ni na voljo.',
  datePicker: {
    from: 'Od',
    to: 'Do',
    cancel: 'Prekliči',
    apply: 'Uporabi',
    month: [
      'Januar',
      'Februar',
      'Marec',
      'April',
      'Maj',
      'Junij',
      'Julij',
      'Avgust',
      'September',
      'Oktober',
      'November',
      'December'
    ],
    week: 'teden',
    weekShot: 't',
    buttonName: {
      date: 'Datum',
      week: 'Teden',
      month: 'Mesec'
    },
    modal: {
      header: 'Opozorilo',
      text: 'Izbran je neveljaven razpon datumov',
      action: 'Sprejmi'
    }
  },
  filters: {
    name: 'Ime'
  },
  scenario: {
    noDataReceived: 'Podatkov nismo prejeli',
    filterPart: {
      name: 'Ime',
      clear: 'Počisti',
      notFound: 'Ni najdeno',
      search: 'Iskanje...'
    }
  },
  header: {
    logout: 'Odjava',
    changePassword: 'Spremeni geslo',
    settings: 'Nastavitve',
    title: 'Prikaži uporabniški vmesnik',
    searchTitle: 'Iskanje uporabnika',
    input: 'Začnite tipkati ime ali prijavo...',
    cancel: 'Prekliči',
    view: 'Pogled',
    id: 'ID',
    login: 'Prijava',
    name: 'Ime',
    phone: 'Telefon',
    email: 'E-pošta',
    stopSettings: 'Ustavi prikaz'
  },
  directTask: {
    addCase: 'Dodaj primer',
    commentCase: 'Komentar',
    actions: 'Dejanja',
    searchProduct: 'PLU/Črtna koda',
    searchProductShort: 'PLU/Črtna koda',
    photo: 'Slika',
    back: 'Nazaj',
    treeEmpty: 'DEJANJA NISO NA VOLJO',
    step: 'Korak',
    sortingButton: 'Razvrščanje',
    sortButton: 'Razvrsti',
    add: 'Dodaj',
    delete: 'Izbriši',
    deleteManualCases: 'Izbriši ročne primere',
    show: 'Pokaži',
    hide: 'Skrij',
    comment: 'Komentiraj',
    showDoneCases: 'Prikaži opravljene naloge',
    hideDoneCases: 'Skrij opravljene naloge'
  },
  gridTable: {
    pluList: 'Seznam PLU'
  },
  casesForm: {
    product: 'Izdelek',
    loadFoto: 'Naloži sliko',
    plu: 'Ime/PLU',
    success: 'Primer ustvarjen',
    delete: 'Izbriši',
    add: 'Ustvari',
    new: 'Novo',
    cancel: 'Prekliči',
    comment: 'Komentar'
  },
  pagination: {
    apply: 'Pojdi',
    placeholder: 'Stran'
  },
  restorePass: {
    noLength: 'Geslo mora imeti vsaj 12 znakov',
    noLowercase: 'Geslo mora vsebovati vsaj eno malo črko',
    noUppercase: 'Geslo mora vsebovati vsaj eno veliko črko',
    noNumber: 'Geslo mora vsebovati vsaj eno številko',
    noMatch: 'Gesli se ne ujemata',
    password: 'Geslo',
    repeatPassword: 'Ponovi geslo',
    ok: 'Sprejmi',
    cancel: 'Prekliči'
  },
  navigation: {
    missions: 'Naloge',
    scenarios: 'Scenariji'
  },
  changePassword: {
    previousPassword: 'Trenutno geslo',
    newPassword: 'Novo geslo',
    confirmPassword: 'Potrditev novega gesla',
    apply: 'Uporabi',
    title: 'Sprememba gesla',
    success: 'Geslo je bilo uspešno spremenjeno',
    wrongPassword: 'Napačno geslo',
    weakPassword: 'Geslo ne ustreza zahtevam',
    passwordsNotMatch: 'Gesli se ne ujemata',
    requirements: 'Novo geslo mora imeti vsaj 12 znakov, 1 veliko in 1 malo črko ter 1 številko'
  },
  auth: {
    login: 'Prijava',
    password: 'Geslo',
    restorePass: 'Obnovi geslo',
    submit: 'Pošlji',
    loginError: 'Prijava ali geslo ni veljavno'
  },
  restorePassForm: {
    restore: 'Obnovi',
    cancel: 'Prekliči',
    info: 'Vnesite svoj e-poštni naslov, ki je registriran v platformi GROSA',
    emailInvalid: 'Vnesen e-poštni naslov ni bil najden',
    email: 'E-pošta',
    success: 'Povezava za obnovitev je bila poslana na vaš e-poštni naslov'
  },
  search: {
    notFound: 'Ni najdeno'
  },
  noMatchPage: {
    title: 'Zahtevane strani ni mogoče najti',
    body: 'Nazaj na začetno stran'
  },
  warnings: {
    title: 'Opozorilo',
    isDesktop: 'Nalaganje fotografij je na voljo le v mobilni različici sistema. Prosimo, uporabite fotoaparat na svojem telefonu ali tablici.'
  },
  errors: {
    netErrorText: 'Napaka omrežja. Prosimo, osvežite stran',
    netErrorReload: 'Osveži',
    netErrorTitle: 'Napaka',
    netErrorClose: 'Zapri',
    noShops: 'Opa! Videti je, da vam še ni bila dodeljena nobena trgovina. Povejte svojemu upravitelju o tem.',
    noCases: 'Zdi se, da naša služba v tej trgovini ni našla niti enega kovčka. Začnite jih ustvarjati sami',
    noAutoOrder: 'Ni nalog za prilagoditev samodejnega vrstnega reda',
    causeError: 'Napaka',
    scenariosEmpty: 'Polje scenarijev je prazno'
  },
  officeUI: {
    resetZoom: 'Ponastavi povečavo'
  },
  gridAutocomplete: {
    placeholder: 'Začnite tipkati ime trgovine',
    notFound: 'Trgovina ni najdena'
  },
  targets: {
    nothing: '',
    shop: 'Trgovina',
    category: 'Kategorija',
    supplier: 'Dobavitelj',
    vendor: 'Dobavitelj',
    brand: 'Blagovna znamka',
    plu: 'PLU',
    day: 'Dan',
    week: 'Teden',
    month: 'Mesec',
    quarter: 'Četrtletje',
    year: 'Leto'
  },
  bubbleChart: {
    qty: 'količina',
    currency: '€'
  },
  chart: {
    quarter: 'četrtletje',
    week: 'teden'
  },
  download: {
    CSV: 'Prenesi CSV',
    XLS: 'Prenesi XLS'
  },
  counterTask: {
    task: 'Odprte naloge',
    noTask: 'Ni nalog'
  },
  filtersShop: {
    shopName: 'Naslov trgovine',
    shopId: 'Ime trgovine'
  },
  chartCaption: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mrd.'
  },
  common: {
    close: 'Zapri',
    userNotFound: 'Uporabnik ni najden',
    yes: 'Da',
    no: 'Ne'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Notranji PLU',
      shop_id: 'ID trgovine',
      ass_type: 'Vrsta razvrstitve',
      category_id3: 'ID oddelka',
      category_id4: 'ID kategorije',
      stock: 'Količina zalog',
      stock_sum: 'Skupna količina zalog',
      product_turnover: 'Obrat',
      days_without_sales: 'Dnevi brez prodaje',
      last_sales_date: 'Datum zadnje prodaje',
      last_sales_volume: 'Zadnja prodajna količina',
      last_shipment_date: 'Zadnji rok dostave',
      last_shipment_volume: 'Zadnja količina dostave',
      promo_start: 'Začetek promocije',
      promo_end: 'Konec promocije',
      promo_period: 'Trajanje promocije',
      is_promo: 'Promocija',
      title: 'Naziv',
      category_name3: 'Oddelek',
      category_name4: 'Kategorija'
    },
    hideReadyTasks: 'Skrij opravljene naloge',
    nothingFound: 'Nič najdenega',
    shopsNotFound: 'Trgovin(a) ni najden(a)',
    chooseShop: 'Izberite trgovino',
    createTasks: 'Ustvari naloge',
    taskCreating: 'Ustvarjanje nalog',
    next: 'Naprej',
    columns: 'Stolpci',
    from: 'Od',
    ok: 'V redu',
    cancel: 'Prekliči',
    filter: 'Filter',
    chooseValue: 'Izberite vrednost',
    enterValue: 'Vnesite vrednost',
    rangeFrom: 'Od',
    rangeTo: 'Do',
    selected: 'Izbrano',
    foundRows: 'Najdeni vnosi',
    createdTasksTotal: 'Skupno ustvarjenih nalog'
  }
};
