import React, { Component } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import axios from 'axios';
import MDSpinner from 'react-md-spinner';
import AppsIcon from '@material-ui/icons/Apps';
import FlagIcon from '@material-ui/icons/Flag';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import RefreshIcon from '@material-ui/icons/Refresh';
import FAQ from '../FAQ';
import {
  chooseMission,
  chooseScenario,
  loadScenarios,
  setDatePickerAction,
  setDrillDownScenario,
  setGridDatePickerAction,
} from '../../store/actions/actions';
import configAxios from '../../config/axios';
import ErrorDialog from '../ErrorDialogSelect';
import ScenarioIcon from '../ScenarioIcon';
import KeyboardReturnIcon from './KeyboardReturnIcon/KeyboardReturnIcon';

import styles from './MissionsNavigation.css';
import history from '../../config/history';

class MissionsNavigation extends Component {
  static prepareScenarios(scenarios) {
    return scenarios.map(item => {
      item['url'] = (item.scenario_kind !== 'office' || item.scenario_kind !== 'redash' ? '/' : '/scenario/') + item['link'];
      return item;
    });
  }

  state = {
    dropdownOpen: false,
    missions: [],
    scenarios: [],
    errorDialog: false,
    missionsLoading: false,
    scenariosLoading: false,
    activeMission: this.props.mission,
  };

  componentDidMount() {
    const missions = localStorage.missions && JSON.parse(localStorage.missions);
    if (missions && missions.length) {
      this.setState(
        {
          missions: missions,
        },
        this.loadScenarios
      );
    } else {
      this.loadMissions();
    }
  }

  loadMissions = () => {
    const tokenStore = this.props.userData.access_token;
    const tokenLocal = localStorage.userData ? JSON.parse(localStorage.userData).access_token : null;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (tokenStore && !tokenLocal) {
      configAxios.defaults.headers = {
        Authorization: 'Bearer ' + tokenStore,
      };
    }

    this.setState({
      missionsLoading: true,
    });

    const promise = configAxios({
      method: 'GET',
      url:
        '/user/missions?expand=blocking,counter,scenarios,netName,companyName&fields=id,name,link,netName,companyName,blocking,counter,scenarios.id,scenarios.name,scenarios.link,scenarios.model_prefix,scenarios.show_counter,scenarios.scenario_type,scenarios.scenario_kind',
      cancelToken: source.token,
    });

    promise.then(response => {
      response.data.data.sort((a, b) => a.name.localeCompare(b.name));
      localStorage.setItem('missions', JSON.stringify(response.data.data));
      this.setState(
        {
          missionsLoading: false,
          missions: response.data.data,
        },
        this.loadScenarios
      );
    });

    return promise;
  };

  loadScenarios = (item, isSubScenario = false) => {
    const {
      mission: initMission,
      dispatch,
      match: {
        params: { missionId = null },
      },
    } = this.props;

    const { missions } = this.state;

    let mission = item ? item : initMission;

    if (missionId && !mission) {
      mission = missions.find(({ id }) => id === +missionId);
    }

    const index = missions.map(item => item.id).indexOf(mission.id);

    if (index !== 0 && !index) {
      return;
    }
    const missionScenarios = missions[index].scenarios;

    const scenarios = MissionsNavigation.prepareScenarios(missionScenarios);
    localStorage.setItem('scenarios', JSON.stringify(scenarios));

    this.setState(
      {
        scenarios: scenarios,
        activeMission: mission,
      },
      () => {
        const { scenarios, activeMission } = this.state;
        return (
          scenarios &&
          scenarios.map(item => {
            if ((activeMission.id === mission.id && item.url === location.pathname) || isSubScenario) {
              // if ((activeMission.id === mission.id) || isSubScenario) {
              dispatch(chooseScenario(item));
            }
          })
        );
      }
    );
  };

  dropdownHandle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  handleDialogState = () => {
    this.setState({
      errorDialog: false,
    });
  };

  overlayClickHandle = () => {
    const {
      match: {
        params: { missionId = null },
      },
    } = this.props;

    const { missions } = this.state;

    let currentMission = JSON.parse(localStorage.getItem('mission'));
    if (missionId) {
      currentMission = missions.find(({ id }) => id === +missionId);
    }

    const index = missions.map(item => item.id).indexOf(currentMission.id);
    const { scenarios } = missions[index];

    const prepScenarios = MissionsNavigation.prepareScenarios(scenarios);

    this.setState({
      dropdownOpen: false,
      activeMission: currentMission,
      scenarios: prepScenarios,
    });
  };

  handleScenarioSelect = item => {
    const { dispatch, purgeFilters } = this.props;
    const { activeMission, scenarios } = this.state;

    const mission = JSON.parse(localStorage.getItem('mission'));
    const scenario = JSON.parse(localStorage.getItem('scenario'));
    localStorage.setItem('scenario', JSON.stringify(item));

    dispatch(setDrillDownScenario({}));
    dispatch(setGridDatePickerAction({}));
    dispatch(loadScenarios(scenarios));

    this.setState({
      dropdownOpen: false,
    });

    if (item.scenario_type !== 'office' || item.scenario_type !== 'redash') {
      dispatch(setDatePickerAction({}));
    }

    if (!mission) {
      dispatch(chooseMission(activeMission));
    }
    if (activeMission.id !== mission.id) {
      dispatch(chooseMission(activeMission));
      dispatch(setDatePickerAction({}));
      if (purgeFilters) {
        purgeFilters({}, scenario.name === item.name ? 'purgeWithFetch' : 'purge');
      }
    }
  };

  renderMissionsContent = () => {
    const { missions, activeMission } = this.state;
    const { translation } = this.props.language;
    return missions && missions.length > 0 ? (
      missions
        .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
        .map(item => {
          const active = activeMission.id === item.id;
          const blocked = item.blocking.filter(block => block.active === true);
          const { name, counter, id, netName, companyName } = item;
          const iconName = item.scenarios[0].scenario_type;

          const userData = this.props.userData;
          let owner = netName;
          // TODO Make a bool mark on the backend that this is a demo user
          if (userData && userData.login.includes('demo') && userData.name.includes('DEMO')) {
            owner = companyName;
          }

          return (
            <div
              role="presentation"
              key={id}
              onClick={() => {
                this.loadScenarios(item);
              }}
              className={cls(styles.dropdownContentElement, {
                [styles.active]: active,
                [styles.dropdownContentElementDisabled]: !!blocked.length,
              })}
            >
              <div className={styles.elementIcon}>
                {iconName ? (
                  <ScenarioIcon icon={iconName} />
                ) : (
                  <div className={styles.defaultIcon}>
                    <FlagIcon />
                  </div>
                )}
              </div>

              <div className={styles.elementName}>
                {name}
                {owner && <div className={styles.elementRetailerName}>{owner}</div>}
              </div>

              {counter !== null && counter !== undefined && counter > 0 && <div className={styles.elementCounter}>!</div>}
            </div>
          );
        })
    ) : (
      <div>{translation.scenario.noDataReceived}</div>
    );
  };
  renderScenariosContent = () => {
    const {
      match: {
        params: { missionId = null, scenarioId = null },
      },
      language: { translation },
      mission,
      scenario,
    } = this.props;

    const { scenarios, activeMission } = this.state;

    return scenarios && scenarios.length > 0 ? (
      scenarios.map(item => {
        const { id, name, url, scenario_kind } = item;
        let activeScenario = mission ? activeMission.id === mission.id && scenario.id === id : null;
        if (scenarioId) {
          activeScenario = activeMission.id === +missionId && id === +scenarioId;
        }

        return (
          <Link
            to={scenario_kind === 'office' || scenario_kind === 'redash'
              ? `/scenario/${activeMission.id}/${id}`
              : `${url}?mid=${activeMission.id}&sid=${id}`}
            onClick={() => {
              if (activeScenario) return;
              if (typeof id === 'number') {
                this.handleScenarioSelect(item);
              }
            }}
            key={item.id}
            class={[styles.dropdownContentElement, activeScenario && styles.active].join(' ')}
          >
            <FormatListBulletedIcon class={styles.scenarioIcon} />
            <div className={styles.elementName}>{name}</div>
          </Link>
        );
      })
    ) : (
      <div>{translation.scenario.noDataReceived}</div>
    );
  };

  render() {
    const {
      mission,
      scenario,
      drillDownScenario,
      location,
      match: {
        params: { missionId = null, scenarioId = null },
      },
      language: { translation },
    } = this.props;

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : missionId;
    const sid = query.has('sid') ? Number(query.get('sid')) : scenarioId;

    const { dropdownOpen, missionsLoading, scenariosLoading, missions } = this.state;

    let currentScenario = scenario;
    let currentMission = mission;
    if (mid) {
      currentMission = missions.length ? missions.find(({ id }) => id == mid) : { name: '' };
    }
    if (sid) {
      currentScenario =
        currentMission.scenarios && currentMission.scenarios.length
          ? currentMission.scenarios.find(({ id }) => id == sid)
          : { name: '' };
    }

    return (
      <Row between="md" class={styles.contentWrap}>
        <Col md={12} style={{ padding: 0 }}>
          <div className={styles.missionNavigationButton}>
            <Link
              role="presentation"
              to="/"
              onClick={() => {
                this.props.dispatch(setDrillDownScenario({}));
              }}
              style={{
                color: '#398bdf',
                marginRight: '15px',
                width: 30,
                height: 30,
              }}
            >
              <KeyboardReturnIcon />
            </Link>
            <div
              role="presentation"
              className={[styles.button, styles.missionNavButton, dropdownOpen ? styles.active : ''].join(' ')}
              onClick={() => {
                if (!missionsLoading) {
                  this.dropdownHandle();
                }
              }}
            >
              {!dropdownOpen && missionsLoading ? (
                <MDSpinner singleColor="#00A5E6" size={30} />
              ) : (
                <AppsIcon
                  style={{
                    color: '#cfcfcf',
                    margin: '-10px',
                    width: 57,
                    height: 57,
                  }}
                />
              )}
            </div>
            <div className={styles.buttonText}>
              <div className={styles.buttonMissionText}>{currentMission.name}</div>
              <div className={styles.buttonScenarioText}>
                {Object.keys(drillDownScenario).length
                  ? drillDownScenario.name
                  : currentScenario
                    ? currentScenario.name
                    : ''}
              </div>
            </div>
          </div>
          {dropdownOpen && <div role="presentation" className={styles.contentOverlay} onClick={this.overlayClickHandle} />}

          {dropdownOpen && (
            <div className={styles.missionNavigationDropdown}>
              {(missionsLoading || scenariosLoading) && (
                <div className={styles.contentPreload}>
                  <MDSpinner singleColor="#00A5E6" size={40} />
                </div>
              )}
              <div className={styles.refreshButton} onClick={this.loadMissions} role="presentation">
                <RefreshIcon style={{ color: '#398bdf' }} />
              </div>
              <div className={styles.missionsDropdownContent}>
                <div className={styles.dropdownTitle}>{translation.navigation.missions}</div>
                <div className={styles.dropdownContent}>{this.renderMissionsContent()}</div>
              </div>
              <div className={styles.scenariosDropdownContent}>
                <div className={styles.dropdownTitle}>{translation.navigation.scenarios}</div>
                <div className={styles.dropdownContent}>{this.renderScenariosContent()}</div>
              </div>
            </div>
          )}
        </Col>
        <ErrorDialog
          active={this.state.errorDialog}
          errorText={this.state.errorMsg}
          handleDialogState={this.handleDialogState}
        />
        <FAQ path="auth" location={location} />
      </Row>
    );
  }
}

MissionsNavigation.defaultProps = {
  mission: null,
  scenario: null,
  userData: {},
  language: {},
  drillDownScenario: {},
  purgeFilters: null,
};

MissionsNavigation.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line
  purgeFilters: PropTypes.func,
  mission: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  scenario: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  drillDownScenario: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  userData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(
  connect(store => {
    return {
      mission: store.mission,
      scenario: store.scenario,
      userData: store.userData,
      language: store.language,
    };
  })(MissionsNavigation)
);
