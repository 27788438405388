export default {
  mobileFirefoxAlert: 'У гэтым браўзэры апрацоўка задач недаступная.',
  datePicker: {
    from: 'З',
    to: 'Да',
    cancel: 'Скасаваць',
    apply: 'Прымяніць',
    month: [
      'Студзень',
      'Люты',
      'Сакавік',
      'Красавік',
      'Май',
      'Чэрвень',
      'Ліпень',
      'Жнівень',
      'Верасень',
      'Кастрычнік',
      'Лістапад',
      'Снежань'
    ],
    week: 'тыдзень',
    weekShot: 'тыд',
    buttonName: {
      date: 'Дата',
      week: 'Тыдзень',
      month: 'Месяц'
    },
    modal: {
      header: 'Папярэджанне',
      text: 'Выбраны недапушчальны дыяпазон дат',
      action: 'Прыняць'
    }
  },
  filters: {
    name: 'Імя'
  },
  scenario: {
    noDataReceived: 'Дадзеныя не атрыманы',
    filterPart: {
      name: 'Імя',
      clear: 'Ачысціць',
      notFound: 'Не знойдзена',
      search: 'Пошук...'
    }
  },
  header: {
    logout: 'Выйсці',
    changePassword: 'Змяніць пароль',
    settings: 'Налады',
    title: 'Прагляд інтэрфейсу карыстальніка',
    searchTitle: 'Пошук карыстальніка',
    input: 'Пачніце пісаць імя ці лагін...',
    cancel: 'Скасаваць',
    view: 'Прагляд',
    id: 'ID',
    login: 'Лагін',
    name: 'Імя',
    phone: 'Тэлефон',
    email: 'Электронная пошта',
    stopSettings: 'Запыніць прагляд'
  },
  directTask: {
    addCase: 'Дадаць выпадак',
    commentCase: 'Каментарыі да выпадку',
    actions: 'Дзеянні',
    searchProduct: 'PLU/Штрых-код',
    searchProductShort: 'PLU/Штрых-код',
    photo: 'Фота',
    back: 'Назад',
    treeEmpty: 'ДАСТУПНЫЯ ДЗЕЙСТВІЯ АДСУТНІ',
    step: 'Крок',
    sortingButton: 'Сартаванне',
    sortButton: 'Сартаваць',
    add: 'Дадаць',
    delete: 'Выдаліць',
    deleteManualCases: 'Выдаліць ручныя выпадкі',
    show: 'Паказаць',
    hide: 'Схаваць',
    comment: 'Каментар',
    showDoneCases: 'Паказаць завершаныя задачы',
    hideDoneCases: 'Схаваць завершаныя задачы'
  },
  gridTable: {
    pluList: 'Спіс PLU'
  },
  casesForm: {
    product: 'Товар',
    loadFoto: 'Загрузіць Фота',
    plu: 'Назва/PLU',
    success: 'Выпадак створаны',
    delete: 'Выдаліць',
    add: 'Стварыць',
    new: 'Новы',
    cancel: 'Адмена',
    comment: 'Каментар'
  },
  pagination: {
    apply: 'Перайсці',
    placeholder: 'Старонка'
  },
  restorePass: {
    noLength: 'Пароль павінен мець прынамсі 12 сімвалаў',
    noLowercase: 'Пароль павінен мець прынамсі адну маленькую літару',
    noUppercase: 'Пароль павінен мець прынамсі адну вялікую літару',
    noNumber: 'Пароль павінен мець прынамсі адзін лічбавы сімвал',
    noMatch: 'Паролі не супадаюць',
    password: 'Пароль',
    repeatPassword: 'Паўтарыце пароль',
    ok: 'Прыйняць',
    cancel: 'Скасаваць'
  },
  navigation: {
    missions: 'Місіі',
    scenarios: 'Сцэнарыі'
  },
  changePassword: {
    previousPassword: 'Бягучы пароль',
    newPassword: 'Новы пароль',
    confirmPassword: 'Пацвердзіце новы пароль',
    apply: 'Прымяніць',
    title: 'Змена пароля',
    success: 'Пароль паспяхова зменены',
    wrongPassword: 'Няправільны пароль',
    weakPassword: 'Пароль не супадае з патрабаваннямі',
    passwordsNotMatch: 'Паролі не супадаюць',
    requirements: 'Новы пароль павінен мець прынамсі 12 сімвалаў, 1 вялікую і маленькую літару і 1 лічбу'
  },
  auth: {
    login: 'Лагін',
    password: 'Пароль',
    restorePass: 'Аднавіць пароль',
    submit: 'Адправіць',
    loginError: 'Няправільны лагін і/або пароль'
  },
  restorePassForm: {
    restore: 'Аднавіць',
    cancel: 'Скасаваць',
    info: 'Калі ласка, увядзіце свой адрас электроннай пошты, зарэгістраваны ў GROSA',
    emailInvalid: 'Пададзены электронны ліст не быў знойдзены',
    email: 'Электронная пошта',
    success: 'Спасылка на аднаўленне была адпраўлена на вашу электронную пошту'
  },
  search: {
    notFound: 'Не знойдзена'
  },
  noMatchPage: {
    title: 'Запытаная старонка не знойдзеная',
    body: 'Вярнуцца на галоўную старонку'
  },
  warnings: {
    title: 'Папярэджанне',
    isDesktop: 'Загрузка фота даступная толькі ў мабільнай версіі сістэмы. Карыстайцеся камерай на вашым тэлефоне або планшэце, калі ласка.'
  },
  errors: {
    netErrorText: 'Памылка сеткі. Калі ласка, перазагрузіце старонку',
    netErrorReload: 'Перазагрузіць',
    netErrorTitle: 'Памылка',
    netErrorClose: 'Закрыць',
    noShops: 'Ой! Падобна, для вас яшчэ не прызначылі ніводнай крамы. Скажыце пра гэта свайму кіраўніку',
    noCases: 'Падобна, што наш сэрвіс не змог знайсці ў гэтай краме ніводнага кейса. Пачніце ствараць іх самастойна',
    noAutoOrder: 'Няма задач на карэкціроўку аўтазаказу',
    causeError: 'Памылка',
    scenariosEmpty: 'Масіў сцэнарыяў пусты'
  },
  officeUI: {
    resetZoom: 'Скінуць маштаб'
  },
  gridAutocomplete: {
    placeholder: 'Пачніце пісаць назву магазіну',
    notFound: 'Магазін не знойдзены'
  },
  targets: {
    nothing: '',
    shop: 'Магазін',
    category: 'Катэгорыя',
    supplier: 'Пастаўшчык',
    vendor: 'Пастаўшчык',
    brand: 'Брэнд',
    plu: 'PLU',
    day: 'Дзень',
    week: 'Тыдзень',
    month: 'Месяц',
    quarter: 'Квартал',
    year: 'Год'
  },
  bubbleChart: {
    qty: 'колькасць',
    currency: 'грн'
  },
  chart: {
    quarter: 'квартал',
    week: 'тыдзень'
  },
  download: {
    CSV: 'Спампаваць CSV',
    XLS: 'Спампаваць XLS'
  },
  counterTask: {
    task: 'Невыкананыя задачы',
    noTask: 'Няма задач'
  },
  filtersShop: {
    shopName: 'Адрас магазіну',
    shopId: 'Назва магазіну'
  },
  chartCaption: {
    thousand: 'тыс.',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Закрыць',
    userNotFound: 'Карыстальнік не знойдзены',
    yes: 'Так',
    no: 'Не',
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Інтэрнальны PLU',
      shop_id: 'ID магазіну',
      ass_type: 'Тып асартыменту',
      category_id3: 'ID секцыі',
      category_id4: 'ID катэгорыі',
      stock: 'Аб\'ём застатку',
      stock_sum: 'Сума балансаў',
      product_turnover: 'Абарот',
      days_without_sales: 'Дні без продажу',
      last_sales_date: 'Дата апошняга продажу',
      last_sales_volume: 'Аб\'ём апошняга продажу',
      last_shipment_date: 'Дата апошняй пастаўкі',
      last_shipment_volume: 'Аб\'ём апошняй пастаўкі',
      promo_start: 'Пачатак прома',
      promo_end: 'Заканчэнне прома',
      promo_period: 'Трываласць прома',
      is_promo: 'Прома',
      title: 'Назва',
      category_name3: 'Секцыя',
      category_name4: 'Катэгорыя'
    },
    hideReadyTasks: 'Схаваць гатовыя задачы',
    nothingFound: 'Нічога не знойдзена',
    shopsNotFound: 'Магазіны не знойдзены',
    chooseShop: 'Выберыце магазін',
    createTasks: 'Стварыць задачы',
    taskCreating: 'Стварэнне задач',
    next: 'Працягнуць',
    columns: 'Ступцы',
    from: 'З',
    ok: 'OK',
    cancel: 'Скасаваць',
    filter: 'Фільтр',
    chooseValue: 'Выберыце значэнне',
    enterValue: 'Увядзіце значэнне',
    rangeFrom: 'З',
    rangeTo: 'Да',
    selected: 'Выбрана',
    foundRows: 'Знойдзена радкоў',
    createdTasksTotal: 'Усяго створана задач'
  }
};
