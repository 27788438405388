export default {
  mobileFirefoxAlert: 'Tässä selaimessa tehtävien käsittely ei ole käytettävissä.',
  datePicker: {
    from: 'Alkaen',
    to: 'Asti',
    cancel: 'Peruuta',
    apply: 'Käytä',
    month: [
      'tammikuu',
      'helmikuu',
      'maaliskuu',
      'huhtikuu',
      'toukokuu',
      'kesäkuu',
      'heinäkuu',
      'elokuu',
      'syyskuu',
      'lokakuu',
      'marraskuu',
      'joulukuu'
    ],
    week: 'viikko',
    weekShot: 'v',
    buttonName: {
      date: 'Päiväys',
      week: 'Viikko',
      month: 'Kuukausi'
    },
    modal: {
      header: 'Varoitus',
      text: 'Virheellinen päivämäärävalikoima valittu',
      action: 'Hyväksy'
    }
  },
  filters: {
    name: 'Nimi'
  },
  scenario: {
    noDataReceived: 'Tietoja ei vastaanotettu',
    filterPart: {
      name: 'Nimi',
      clear: 'Tyhjennä',
      notFound: 'Ei löytynyt',
      search: 'Etsi...'
    }
  },
  header: {
    logout: 'Kirjaudu ulos',
    changePassword: 'Vaihda salasana',
    settings: 'Asetukset',
    title: 'Näytä käyttöliittymä',
    searchTitle: 'Etsi käyttäjää',
    input: 'Aloita kirjoittamaan nimeä tai käyttäjätunnusta...',
    cancel: 'Peruuta',
    view: 'Näytä',
    id: 'ID',
    login: 'Kirjautuminen',
    name: 'Nimi',
    phone: 'Puhelin',
    email: 'Sähköposti',
    stopSettings: 'Lopeta tarkastelu'
  },
  directTask: {
    addCase: 'Lisää tapaus',
    commentCase: 'kommentoiTapaus',
    actions: 'Toimet',
    searchProduct: 'PLU/Barkoodi',
    searchProductShort: 'PLU/Barkoodi',
    photo: 'Valokuva',
    back: 'Takaisin',
    treeEmpty: 'TOIMINNOT EIVÄT OLE KÄYTETTÄVISSÄ',
    step: 'Vaihe',
    sortingButton: 'Lajittelu',
    sortButton: 'Lajittele',
    add: 'Lisää',
    delete: 'Poista',
    deleteManualCases: 'Poista manuaaliset tapaukset',
    show: 'Näytä',
    hide: 'Piilota',
    comment: 'Kommentti',
    showDoneCases: 'Näytä valmiit tehtävät',
    hideDoneCases: 'Piilota valmiit tehtävät'
  },
  gridTable: {
    pluList: 'PLU-lista'
  },
  casesForm: {
    product: 'Tuote',
    loadFoto: 'Lataa kuva',
    plu: 'Nimi/PLU',
    success: 'Tapa luotu',
    delete: 'Poista',
    add: 'Luoda',
    new: 'Uusi',
    cancel: 'Peruuttaa',
    comment: 'Kommentti'
  },
  pagination: {
    apply: 'Siirry',
    placeholder: 'Sivu'
  },
  restorePass: {
    noLength: 'Salasanan tulee olla vähintään 12 merkkiä pitkä',
    noLowercase: 'Salasanan tulee sisältää vähintään yksi pieni kirjain',
    noUppercase: 'Salasanan tulee sisältää vähintään yksi iso kirjain',
    noNumber: 'Salasanan tulee sisältää vähintään yksi numero',
    noMatch: 'Salasanat eivät täsmää',
    password: 'Salasana',
    repeatPassword: 'Toista salasana',
    ok: 'Hyväksy',
    cancel: 'Peruuta'
  },
  navigation: {
    missions: 'Tehtävät',
    scenarios: 'Skenaariot'
  },
  changePassword: {
    previousPassword: 'Nykyinen salasana',
    newPassword: 'Uusi salasana',
    confirmPassword: 'Toista uusi salasana',
    apply: 'Käytä',
    title: 'Vaihda salasana',
    success: 'Salasana on vaihdettu onnistuneesti',
    wrongPassword: 'Väärä salasana',
    weakPassword: 'Salasana ei vastaa vaatimuksia',
    passwordsNotMatch: 'Salasanat eivät täsmää',
    requirements: 'Uuden salasanan tulee olla vähintään 12 merkkiä pitkä, sisältää vähintään yhden isoja ja pieniä kirjaimia sekä yhden numeron'
  },
  auth: {
    login: 'Kirjaudu sisään',
    password: 'Salasana',
    restorePass: 'Palauta salasana',
    submit: 'Lähetä',
    loginError: 'Kirjautuminen epäonnistui, käyttäjätunnus ja/tai salasana ovat virheellisiä'
  },
  restorePassForm: {
    restore: 'Palauta',
    cancel: 'Peruuta',
    info: 'Syötä sähköpostiosoitteesi, joka on rekisteröity GROSA -järjestelmään',
    emailInvalid: 'Annettu sähköpostiosoite ei löytynyt',
    email: 'Sähköposti',
    success: 'Palautuslinkki on lähetetty sähköpostiisi'
  },
  search: {
    notFound: 'Ei löytynyt'
  },
  noMatchPage: {
    title: 'Pyydettyä sivua ei löytynyt',
    body: 'Palaa etusivulle'
  },
  warnings: {
    title: 'Varoitus',
    isDesktop: 
      'Valokuvien lataaminen on mahdollista vain järjestelmän mobiiliversiossa. Käytä puhelimen tai tabletin kameraa.'
  },
  errors: {
    netErrorText: 'Verkkovirhe. Päivitä sivu',
    netErrorReload: 'Lataa uudelleen',
    netErrorTitle: 'Virhe',
    netErrorClose: 'Sulje',
    noShops: 'Hups! Näyttää siltä, ​​että sinulle ei ole vielä määritetty yhtään kauppaa. Kerro siitä esimiehellesi',
    noCases: 'Näyttää siltä, että palvelumme ei löytänyt mitään tapausta tästä kaupasta. Aloita niiden luominen itse.',
    noAutoOrder: 'Automaattisen järjestyksen säätämiseen ei ole tehtäviä.',
    causeError: 'Virhe',
    scenariosEmpty: 'Skenaariotaulukko on tyhjä'
  },
  officeUI: {
    resetZoom: 'Nollaa zoomaus'
  },
  gridAutocomplete: {
    placeholder: 'Aloita kirjoittamaan kaupan nimeä',
    notFound: 'Kauppaa ei löytynyt'
  },
  targets: {
    nothing: '',
    shop: 'Kauppa',
    category: 'Luokka',
    supplier: 'Toimittaja',
    vendor: 'Myyjä',
    brand: 'Merkki',
    plu: 'PLU',
    day: 'Päivä',
    week: 'Viikko',
    month: 'Kuukausi',
    quarter: 'Neljännesvuosi',
    year: 'Vuosi'
  },
  bubbleChart: {
    qty: 'kpl',
    currency: '€'
  },
  chart: {
    quarter: 'Neljännesvuosi',
    week: 'Viikko'
  },
  download: {
    CSV: 'Lataa CSV',
    XLS: 'Lataa XLS'
  },
  counterTask: {
    task: 'Odottavat tehtävät',
    noTask: 'Ei tehtäviä'
  },
  filtersShop: {
    shopName: 'Kaupan osoite',
    shopId: 'Kaupan nimi'
  },
  chartCaption: {
    thousand: 'tuhatta',
    million: 'miljoonaa',
    billion: 'miljardia'
  },
  common: {
    close: 'Sulje',
    userNotFound: 'Käyttäjää ei löytynyt',
    yes: 'Kyllä',
    no: 'Ei'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Sisäinen PLU',
      shop_id: 'Kaupan ID',
      ass_type: 'Lajitelmatyyppi',
      category_id3: 'Osaston ID',
      category_id4: 'Luokan ID',
      stock: 'Jäännösten määrä',
      stock_sum: 'Tasojen summa',
      product_turnover: 'Kiertonopeus',
      days_without_sales: 'Päivät ilman myyntiä',
      last_sales_date: 'Viimeisen myynnin päivämäärä',
      last_sales_volume: 'Viimeinen myyntimäärä',
      last_shipment_date: 'Viimeinen toimituspäivä',
      last_shipment_volume: 'Viimeinen toimitusmäärä',
      promo_start: 'Kampanjan alku',
      promo_end: 'Kampanjan loppu',
      promo_period: 'Kampanjan ajanjakso',
      is_promo: 'Kampanja',
      title: 'Nimi',
      category_name3: 'Osasto',
      category_name4: 'Luokka'
    },
    hideReadyTasks: 'Piilota valmiit tehtävät',
    nothingFound: 'Ei tuloksia',
    shopsNotFound: 'Kauppoja ei löytynyt',
    chooseShop: 'Valitse kauppa',
    createTasks: 'Luo tehtäviä',
    taskCreating: 'Luodaan tehtäviä',
    next: 'Seuraava',
    columns: 'Sarakkeet',
    from: 'Alkaen',
    ok: 'OK',
    cancel: 'Peruuta',
    filter: 'Suodata',
    chooseValue: 'Valitse arvo',
    enterValue: 'Syötä arvo',
    rangeFrom: 'Alkaen',
    rangeTo: 'Asti',
    selected: 'Valittu',
    foundRows: 'Löydetyt rivit',
    createdTasksTotal: 'Luodut tehtävät yhteensä'
  }
};
