export default {
  mobileFirefoxAlert: "Dans ce navigateur, le traitement des tâches n'est pas disponible.",
  datePicker: {
    from: 'De',
    to: 'À',
    cancel: 'Annuler',
    apply: 'Appliquer',
    month: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
    week: 'semaine',
    weekShot: 'sem',
    buttonName: {
      date: 'Date',
      week: 'Semaine',
      month: 'Mois'
    },
    modal: {
      header: 'Avertissement',
      text: "Plage de dates invalide sélectionnée",
      action: 'Accepter'
    }
  },
  filters: {
    name: 'Nom'
  },
  header: {
    logout: 'Se déconnecter',
    changePassword: 'Changer le mot de passe',
    settings: 'Paramètres',
    title: "Voir l'interface utilisateur",
    searchTitle: 'Rechercher un utilisateur',
    input: 'Commencez à taper le nom ou le login...',
    cancel: 'Annuler',
    view: 'Voir',
    id: 'ID',
    login: 'Login',
    name: 'Nom',
    phone: 'Téléphone',
    email: 'Email',
    stopSettings: 'Arrêter la visualisation'
  },
  scenario: {
    noDataReceived: 'Aucune donnée reçue',
    filterPart: {
      name: 'Nom',
      clear: 'Effacer',
      notFound: 'Non trouvé',
      search: 'Recherche...'
    }
  },
  directTask: {
    addCase: 'Ajouter un cas',
    addComment: 'Commentaire',
    actions: 'Actions',
    searchProduct: 'PLU/Code-barres',
    searchProductShort: 'PLU/Code-barres',
    photo: 'Photo',
    back: 'Retour',
    treeEmpty: 'ACTIONS INDISPONIBLES',
    step: 'Étape',
    sortingButton: 'Tri',
    sortButton: 'Trier',
    add: 'Ajouter',
    delete: 'Supprimer',
    deleteManualCases: 'Supprimer les cas manuels',
    show: 'Afficher',
    hide: 'Cacher',
    comment: 'Commentaire',
    showDoneCases: 'Afficher les tâches terminées',
    hideDoneCases: 'Masquer les tâches terminées'
  },
  gridTable: {
    pluList: 'Liste PLU'
  },
  casesForm: {
    product: 'Produit',
    loadFoto: 'Charger une photo',
    plu: 'Entrer un nom/PLU',
    success: 'Tâche créée',
    delete: 'Supprimer',
    add: 'Créer',
    new: 'Nouveau',
    cancel: 'Annuler',
    comment: 'Commentaire'
  },
  pagination: {
    apply: 'Aller',
    placeholder: 'Page'
  },
  restorePass: {
    noLength: 'Le mot de passe doit comporter au moins 12 caractères',
    noLowercase: 'Le mot de passe doit comporter au moins une lettre minuscule',
    noUppercase: 'Le mot de passe doit comporter au moins une lettre majuscule',
    noNumber: 'Le mot de passe doit comporter au moins un symbole numérique',
    noMatch: 'Les mots de passe ne correspondent pas',
    password: 'Mot de passe',
    repeatPassword: 'Répéter le mot de passe',
    ok: 'Accepter',
    cancel: 'Annuler'
  },
  navigation: {
    missions: 'Missions',
    scenarios: 'Scénarios'
  },
  changePassword: {
    previousPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Nouveau mot de passe à nouveau',
    apply: 'Appliquer',
    title: 'Changer le mot de passe',
    success: 'Le mot de passe a été changé avec succès',
    wrongPassword: 'Mauvais mot de passe',
    weakPassword: "Le mot de passe ne correspond pas aux exigences",
    passwordsNotMatch: 'Les mots de passe ne correspondent pas',
    requirements: 'Le nouveau mot de passe doit comporter au moins 12 caractères, 1 lettre majuscule et minuscule et 1 chiffre' +
      'Le nouveau mot de passe doit comporter au moins 12 caractères, 1 lettre majuscule et minuscule et 1 chiffre'
  },
  auth: {
    login: 'Login',
    password: 'Mot de passe',
    restorePass: 'Restaurer le mot de passe',
    submit: 'Connexion',
    loginError: 'Login et/ou mot de passe non valides'
  },
  restorePassForm: {
    restore: 'Restaurer',
    cancel: 'Annuler',
    info: 'Veuillez saisir votre e-mail enregistré dans la plateforme GROSA',
    emailInvalid: 'L\'e-mail soumis n\'a pas été trouvé',
    email: 'E-mail',
    success: 'Le lien de restauration a été envoyé à votre e-mail'
  },
  search: {
    notFound: 'Non trouvé'
  },
  noMatchPage: {
    title: 'Page demandée introuvable',
    body: 'Retour à la page d\'accueil'
  },
  warnings: {
    title: 'Avertissement',
    isDesktop: "Le téléchargement des photos n'est disponible que dans la version mobile du système. Veuillez utiliser l'appareil photo de votre téléphone ou de votre tablette."
  },
  errors: {
    netErrorText: "Erreur réseau. Veuillez recharger la page",
    netErrorReload: 'Recharger',
    netErrorTitle: 'Erreur',
    netErrorClose: 'Fermer',
    noShops: 'OH! Il semble qu\'aucun magasin ne vous ait encore été attribué. Parlez-en à votre manager',
    noCases: "Il semble que notre service n'ait trouvé aucun cas dans ce magasin. Commencez à les créer vous-même",
    noAutoOrder: 'Il n\'y a aucune tâche pour ajuster l\'ordre automatique',
    causeError: 'Erreur',
    scenariosEmpty: 'Vous n\'avez pas de scripts'
  },
  officeUI: {
    resetZoom: 'Réinitialiser le zoom'
  },
  gridAutocomplete: {
    placeholder: 'Commencez à taper le nom du magasin',
    notFound: 'Magasin non trouvé'
  },
  targets: {
    nothing: '',
    shop: 'Magasin',
    category: 'Catégorie',
    supplier: 'Fournisseur',
    vendor: 'Vendeur',
    brand: 'Marque',
    plu: 'PLU',
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    quarter: 'Trimestre',
    year: 'Année'
  },
  bubbleChart: {
    qty: 'qté',
    currency: '€'
  },
  chart: {
    quarter: 'trimestre',
    week: 'semaine'
  },
  download: {
    CSV: 'Télécharger CSV',
    XLS: 'Télécharger XLS'
  },
  counterTask: {
    task: 'Tâches en attente',
    noTask: 'Pas de tâches'
  },
  filtersShop: {
    shopName: 'Adresse du magasin',
    shopId: 'Nom du magasin'
  },
  chartCaption: {
    thousand: 'k',
    million: 'M',
    billion: 'G'
  },
  common: {
    close: 'Fermer',
    userNotFound: 'Utilisateur non trouvé',
    yes: 'Oui',
    no: 'Non',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "PLU interne",
      shop_id: "ID du magasin",
      ass_type: "Type d'assortiment",
      category_id3: "ID de section",
      category_id4: "ID de catégorie",
      stock: "Volume des restes",
      stock_sum: "Montant des soldes",
      product_turnover: "Rotation des produits",
      days_without_sales: "Jours sans ventes",
      last_sales_date: "Date de la dernière vente",
      last_sales_volume: "Volume de la dernière vente",
      last_shipment_date: "Date de la dernière livraison",
      last_shipment_volume: "Dernier volume de livraison",
      promo_start: "Début de la promotion",
      promo_end: "Fin de la promotion",
      promo_period: "Période de promotion",
      is_promo: "Promo",
      title: "Nom",
      category_name3: "Section",
      category_name4: "Catégorie"
    },
    hideReadyTasks: "Masquer les tâches prêtes",
    nothingFound: "Rien trouvé",
    shopsNotFound: "Magasins introuvables",
    chooseShop: "Choisir un magasin",
    createTasks: "Créer des tâches",
    taskCreating: "Création de tâches",
    next: "Continuer",
    columns: "Colonnes",
    from: "De",
    ok: "OK",
    cancel: "Annuler",
    filter: "Filtre",
    chooseValue: "Choisir une valeur",
    enterValue: "Saisir une valeur",
    rangeFrom: "De",
    rangeTo: "À",
    selected: "Sélectionné",
    foundRows: "Lignes trouvées",
    createdTasksTotal: "Total des tâches créées"
  }
};
