import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Iframe from 'react-iframe';
import has from 'lodash/has';

import './RedashLink.css';

export default class RedashLink extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.props = props;
    this.state = {
      iFrameHeight: 0,
      isLoaded: false,
      size: document.getElementById('anchor').offsetLeft,
    };
    window.onresize = () => {
      this.setState({
        size: document.getElementById('anchor').offsetLeft,
      });
    };
  }

  receiveMessage = event => {
    const height = JSON.parse(event.data);
    console.log(height);
    this.setState({
      iFrameHeight: height,
    });
  };

  getRememberToken = cookie => {
    if(cookie == null || cookie === undefined) return cookie;
    try {
      const start = Array.isArray(cookie) ? cookie : JSON.parse(cookie);
      const data = start[0].split('=');
      return data[1].split(';')[0];
    } catch (error) {
      return cookie;
    }
  }

  render() {
    const { link } = this.props;
    const { iFrameHeight, isLoaded } = this.state;
    if(!has(link,['dashboard-link', 'cookies']) && !has(link,['dashboard-link', 'link'])) return null;
    return (
      <div style={{ position: "absolute" , left: 0, width: "100%" }}>
        <Iframe
          title="redash"
          style={{
            width: '100%',
            height: iFrameHeight + 'px',
            overflow: 'visible',
            visibility: !iFrameHeight ? 'hidden' : 'visible',
          }}
          id="iframe"
          onLoad={() => {
            if (!isLoaded) {
              const redashCookies = JSON.parse(localStorage.getItem("redashCookies"));
              const newCookies = link[`dashboard-link`].cookies;
              if(this.getRememberToken(redashCookies) !== this.getRememberToken(newCookies)){
                localStorage.setItem("redashCookies", JSON.stringify(newCookies));

                document.getElementById('iframe').contentWindow.postMessage(JSON.stringify(link[`dashboard-link`]), '*');
              }
              window.addEventListener('message', this.receiveMessage, false);
              this.setState({
                isLoaded: true
              });
            }
          }}
          src={link[`dashboard-link`].link}
          width="100%"
          height={iFrameHeight + 'px'}
          frameBorder="0"
        />
        {!iFrameHeight && <CircularProgress style={{ position: 'fixed', top: '50%', left: '50%', color: '#398bdf' }} />}
      </div>
    );
  }
}

RedashLink.propTypes = {
  link: PropTypes.object.isRequired, // eslint-disable-line
};
