export default {
  mobileFirefoxAlert: 'Ova funkcionalnost nije dostupna u ovom pregledniku.',
  datePicker: {
    from: 'Od',
    to: 'Do',
    cancel: 'Odustani',
    apply: 'Primijeni',
    month: [
      'Siječanj',
      'Veljača',
      'Ožujak',
      'Travanj',
      'Svibanj',
      'Lipanj',
      'Srpanj',
      'Kolovoz',
      'Rujan',
      'Listopad',
      'Studeni',
      'Prosinac'
    ],
    week: 'tjedan',
    weekShot: 'tj.',
    buttonName: {
      date: 'Datum',
      week: 'Tjedan',
      month: 'Mjesec'
    },
    modal: {
      header: 'Upozorenje',
      text: 'Odabrani datumski raspon nije valjan',
      action: 'Prihvati'
    }
  },
  filters: {
    name: 'Ime'
  },
  scenario: {
    noDataReceived: 'Nisu primljeni podaci',
    filterPart: {
      name: 'Ime',
      clear: 'Očisti',
      notFound: 'Nije pronađeno',
      search: 'Pretraži...'
    }
  },
  header: {
    logout: 'Odjava',
    changePassword: 'Promijeni lozinku',
    settings: 'Postavke',
    title: 'Pregled korisničkog sučelja',
    searchTitle: 'Pretraži korisnika',
    input: 'Počnite tipkati ime ili korisničko ime...',
    cancel: 'Odustani',
    view: 'Pregled',
    id: 'ID',
    login: 'Korisničko ime',
    name: 'Ime',
    phone: 'Telefon',
    email: 'E-pošta',
    stopSettings: 'Zaustavi pregled'
  },
  directTask: {
    addCase: 'Dodaj slučaj',
    commentCase: 'Komentar na slučaj',
    actions: 'Akcije',
    searchProduct: 'PLU/Barkod',
    searchProductShort: 'PLU/Barkod',
    photo: 'Slika',
    back: 'Natrag',
    treeEmpty: 'AKCIJE NISU DOSTUPNE',
    step: 'Korak',
    sortingButton: 'Sortiranje',
    sortButton: 'Sortiraj',
    add: 'Dodaj',
    delete: 'Obriši',
    deleteManualCases: 'Obriši ručne slučajeve',
    show: 'Prikaži',
    hide: 'Sakrij',
    comment: 'Komentar',
    showDoneCases: 'Prikaži dovršene zadatke',
    hideDoneCases: 'Sakrij dovršene zadatke'
  },
  gridTable: {
    pluList: 'Popis PLU'
  },
  casesForm: {
    product: 'Proizvod',
    loadFoto: 'Učitaj fotografiju',
    plu: 'Naziv/PLU',
    success: 'Slučaj je kreiran',
    delete: 'Izbrisati',
    add: 'Stvoriti',
    new: 'Novi',
    cancel: 'Otkazati',
    comment: 'Komentar'
  },
  pagination: {
    apply: 'Idi',
    placeholder: 'Stranica'
  },
  restorePass: {
    noLength: 'Lozinka mora imati barem 12 znakova',
    noLowercase: 'Lozinka mora sadržavati barem jedno malo slovo',
    noUppercase: 'Lozinka mora sadržavati barem jedno veliko slovo',
    noNumber: 'Lozinka mora sadržavati barem jedan numerički simbol',
    noMatch: 'Lozinke se ne podudaraju',
    password: 'Lozinka',
    repeatPassword: 'Ponovi lozinku',
    ok: 'Prihvati',
    cancel: 'Odustani'
  },
  navigation: {
    missions: 'Misije',
    scenarios: 'Scenariji'
  },
  changePassword: {
    previousPassword: 'Trenutna lozinka',
    newPassword: 'Nova lozinka',
    confirmPassword: 'Ponovi novu lozinku',
    apply: 'Primijeni',
    title: 'Promijeni lozinku',
    success: 'Lozinka je uspješno promijenjena',
    wrongPassword: 'Pogrešna lozinka',
    weakPassword: 'Lozinka ne udovoljava zahtjevima',
    passwordsNotMatch: 'Lozinke se ne podudaraju',
    requirements: 'Nova lozinka mora sadržavati barem 12 znakova, 1 veliko i malo slovo te 1 broj '
  },
  auth: {
    login: 'Prijava',
    password: 'Lozinka',
    restorePass: 'Obnovi lozinku',
    submit: 'Potvrdi',
    loginError: 'Korisničko ime i/ili lozinka nisu valjani'
  },
  restorePassForm: {
    restore: 'Obnovi',
    cancel: 'Odustani',
    info: 'Unesite svoju e-poštu koja je registrirana na GROSAi',
    emailInvalid: 'Unesena e-pošta nije pronađena',
    email: 'E-pošta',
    success: 'Poveznica za obnovu poslana je na vašu e-poštu'
  },
  search: {
    notFound: 'Nije pronađeno'
  },
  noMatchPage: {
    title: 'Tražena stranica nije pronađena',
    body: 'Povratak na početnu stranicu'
  },
  warnings: {
    title: 'Upozorenje',
    isDesktop: 'Prijenos slika dostupan je samo u mobilnoj verziji sustava. Koristite kameru na svom telefonu ili tabletu.'
  },
  errors: {
    netErrorText: 'Pogreška mreže. Osvježite stranicu, molimo vas.',
    netErrorReload: 'Osvježi',
    netErrorTitle: 'Pogreška',
    netErrorClose: 'Zatvori',
    noShops: 'OH! Čini se da vam još nije dodijeljena nijedna trgovina. Recite svom upravitelju o tome',
    noCases: 'Čini se da naša služba nije uspjela pronaći niti jednu kutiju u ovoj trgovini. Počnite ih stvarati sami',
    noAutoOrder: 'Nema zadataka za podešavanje automatskog redoslijeda',
    causeError: 'Pogreška',
    scenariosEmpty: 'Niz scenarija je prazan'
  },
  officeUI: {
    resetZoom: 'Poništi zumiranje'
  },
  gridAutocomplete: {
    placeholder: 'Počnite tipkati naziv trgovine',
    notFound: 'Trgovina nije pronađena'
  },
  targets: {
    nothing: '',
    shop: 'Trgovina',
    category: 'Kategorija',
    supplier: 'Dobavljač',
    vendor: 'Prodavač',
    brand: 'Marka',
    plu: 'PLU',
    day: 'Dan',
    week: 'Tjedan',
    month: 'Mjesec',
    quarter: 'Kvartal',
    year: 'Godina'
  },
  bubbleChart: {
    qty: 'količina',
    currency: 'HRK'
  },
  chart: {
    quarter: 'kvartal',
    week: 'tjedan'
  },
  download: {
    CSV: 'Preuzmi CSV',
    XLS: 'Preuzmi XLS'
  },
  counterTask: {
    task: 'Nedovršeni zadaci',
    noTask: 'Nema zadataka'
  },
  filtersShop: {
    shopName: 'Adresa trgovine',
    shopId: 'Naziv trgovine'
  },
  chartCaption: {
    thousand: 'tis.',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Zatvori',
    userNotFound: 'Korisnik nije pronađen',
    yes: 'Da',
    no: 'Ne'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Unutarnji PLU',
      shop_id: 'ID trgovine',
      ass_type: 'Vrsta asortimana',
      category_id3: 'ID sekcije',
      category_id4: 'ID kategorije',
      stock: 'Količina zaliha',
      stock_sum: 'Ukupna vrijednost zaliha',
      product_turnover: 'Promet',
      days_without_sales: 'Dani bez prodaje',
      last_sales_date: 'Datum zadnje prodaje',
      last_sales_volume: 'Zadnja količina prodaje',
      last_shipment_date: 'Zadnji datum isporuke',
      last_shipment_volume: 'Zadnji volumen isporuke',
      promo_start: 'Početak promocije',
      promo_end: 'Završetak promocije',
      promo_period: 'Razdoblje promocije',
      is_promo: 'Promocija',
      title: 'Naziv',
      category_name3: 'Sekcija',
      category_name4: 'Kategorija'
    },
    hideReadyTasks: 'Sakrij završene zadatke',
    nothingFound: 'Ništa nije pronađeno',
    shopsNotFound: 'Izgleda da nisu dodijeljene trgovine. Obavijestite nadzornika o tome.',
    chooseShop: 'Odaberi trgovinu',
    createTasks: 'Izradi zadatke',
    taskCreating: 'Izrada zadataka',
    next: 'Nastavi',
    columns: 'Stupci',
    from: 'Od',
    ok: 'U redu',
    cancel: 'Odustani',
    filter: 'Filter',
    chooseValue: 'Odaberi vrijednost',
    enterValue: 'Unesi vrijednost',
    rangeFrom: 'Od',
    rangeTo: 'Do',
    selected: 'Odabrano',
    foundRows: 'Pronađeni redovi',
    createdTasksTotal: 'Ukupno izrađenih zadataka'
  }
};
