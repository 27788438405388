export default {
  mobileFirefoxAlert: 'In diesem Browser ist die Aufgabenverarbeitung nicht verfügbar.',
  datePicker: {
    from: 'Von',
    to: 'Bis',
    cancel: 'Abbrechen',
    apply: 'Anwenden',
    month: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    week: 'Woche',
    weekShot: 'W',
    buttonName: {
      date: 'Datum',
      week: 'Woche',
      month: 'Monat'
    },
    modal: {
      header: 'Warnung',
      text: 'Ungültiger Datumsbereich ausgewählt',
      action: 'Akzeptieren'
    }
  },
  filters: {
    name: 'Name'
  },
  scenario: {
    noDataReceived: 'Keine Daten erhalten',
    filterPart: {
      name: 'Name',
      clear: 'Löschen',
      notFound: 'Nicht gefunden',
      search: 'Suchen...'
    }
  },
  header: {
    logout: 'Abmelden',
    changePassword: 'Passwort ändern',
    settings: 'Einstellungen',
    title: 'Benutzeroberfläche anzeigen',
    searchTitle: 'Benutzer suchen',
    input: 'Geben Sie den Namen oder das Login ein...',
    cancel: 'Abbrechen',
    view: 'Ansicht',
    id: 'ID',
    login: 'Anmelden',
    name: 'Name',
    phone: 'Telefon',
    email: 'E-Mail',
    stopSettings: 'Anzeige stoppen'
  },
  directTask: {
    addCase: 'Fall hinzufügen',
    commentCase: 'Fall kommentieren',
    actions: 'Aktionen',
    searchProduct: 'PLU/Strichcode suchen',
    searchProductShort: 'PLU/Strichcode suchen',
    photo: 'Foto',
    back: 'Zurück',
    treeEmpty: 'AKTIONEN NICHT VERFÜGBAR',
    step: 'Schritt',
    sortingButton: 'Sortierung',
    sortButton: 'Sortieren',
    add: 'Hinzufügen',
    delete: 'Löschen',
    deleteManualCases: 'Manuelle Fälle löschen',
    show: 'Anzeigen',
    hide: 'Ausblenden',
    comment: 'Kommentar',
    showDoneCases: 'Erledigte Aufgaben anzeigen',
    hideDoneCases: 'Erledigte Aufgaben ausblenden'
  },
  gridTable: {
    pluList: 'PLU-Liste'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Foto laden',
    plu: 'Name/PLU',
    success: 'Fall erstellt',
    delete: 'Löschen',
    add: 'Erstellen',
    new: 'Neu',
    cancel: 'Abbrechen',
    comment: 'Kommentar'
  },
  pagination: {
    apply: 'Los',
    placeholder: 'Seite'
  },
  restorePass: {
    noLength: 'Das Passwort muss mindestens 12 Zeichen lang sein',
    noLowercase: 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
    noUppercase: 'Das Passwort muss mindestens einen Großbuchstaben enthalten',
    noNumber: 'Das Passwort muss mindestens ein numerisches Symbol enthalten',
    noMatch: 'Passwörter stimmen nicht überein',
    password: 'Passwort',
    repeatPassword: 'Passwort erneut eingeben',
    ok: 'Akzeptieren',
    cancel: 'Abbrechen'
  },
  navigation: {
    missions: 'Missionen',
    scenarios: 'Szenarien'
  },
  changePassword: {
    previousPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    confirmPassword: 'Neues Passwort erneut eingeben',
    apply: 'Anwenden',
    title: 'Passwort ändern',
    success: 'Passwort wurde erfolgreich geändert',
    wrongPassword: 'Falsches Passwort',
    weakPassword: 'Das Passwort entspricht nicht den Anforderungen',
    passwordsNotMatch: 'Passwörter stimmen nicht überein',
    requirements:
      'Das neue Passwort muss mindestens 12 Zeichen, 1 Groß- und Kleinbuchstaben und 1 Nummer enthalten'
  },
  auth: {
    login: 'Anmelden',
    password: 'Passwort',
    restorePass: 'Passwort wiederherstellen',
    submit: 'Senden',
    loginError: 'Benutzername und/oder Passwort ungültig'
  },
  restorePassForm: {
    restore: 'Wiederherstellen',
    cancel: 'Abbrechen',
    info: 'Bitte geben Sie Ihre E-Mail-Adresse ein, die im GROSA registriert ist',
    emailInvalid: 'Die eingegebene E-Mail wurde nicht gefunden',
    email: 'E-Mail',
    success: 'Wiederherstellungslink wurde an Ihre E-Mail gesendet'
  },
  search: {
    notFound: 'Nicht gefunden'
  },
  noMatchPage: {
    title: 'Angeforderte Seite nicht gefunden',
    body: 'Zurück zur Startseite'
  },
  warnings: {
    title: 'Warnung',
    isDesktop: 'Das Hochladen von Fotos ist nur in der mobilen Version des Systems möglich. Verwenden Sie bitte die Kamera Ihres Telefons oder Tablets.'
  },
  errors: {
    netErrorText: 'Netzwerkfehler. Bitte laden Sie die Seite neu.',
    netErrorReload: 'Neu laden',
    netErrorTitle: 'Fehler',
    netErrorClose: 'Schließen',
    noShops: 'OH! Offenbar wurden Ihnen noch keine Shops zugewiesen. Informieren Sie Ihren Vorgesetzten darüber',
    noCases: 'Es scheint, dass unser Service in diesem Geschäft keinen einzigen Fall finden konnte. Fangen Sie an, sie selbst zu erstellen',
    noAutoOrder: 'Es gibt keine Aufgaben zum Anpassen der automatischen Reihenfolge',
    causeError: 'Fehler',
    scenariosEmpty: 'Szenarienarray ist leer'
  },
  officeUI: {
    resetZoom: 'Zoom zurücksetzen'
  },
  gridAutocomplete: {
    placeholder: 'Geben Sie den Geschäftsnamen ein',
    notFound: 'Geschäft nicht gefunden'
  },
  targets: {
    nothing: '',
    shop: 'Geschäft',
    category: 'Kategorie',
    supplier: 'Lieferant',
    vendor: 'Anbieter',
    brand: 'Marke',
    plu: 'PLU',
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    quarter: 'Quartal',
    year: 'Jahr'
  },
  bubbleChart: {
    qty: 'Anzahl',
    currency: 'Rubel'
  },
  chart: {
    quarter: 'Quartal',
    week: 'Woche'
  },
  download: {
    CSV: 'CSV herunterladen',
    XLS: 'XLS herunterladen'
  },
  counterTask: {
    task: 'Ausstehende Aufgaben',
    noTask: 'Keine Aufgaben'
  },
  filtersShop: {
    shopName: 'Geschäftsadresse',
    shopId: 'Geschäftsname'
  },
  chartCaption: {
    thousand: 'Tsd.',
    million: 'Mio.',
    billion: 'Mrd.'
  },
  common: {
    close: 'Schließen',
    userNotFound: 'Benutzer nicht gefunden',
    yes: 'Ja',
    no: 'Nein'
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "Interner PLU",
      shop_id: "Geschäfts-ID",
      ass_type: "Sortimentstyp",
      category_id3: "Abschnitts-ID",
      category_id4: "Kategorie-ID",
      stock: "Bestandsvolumen",
      stock_sum: "Summe der Bestände",
      product_turnover: "Umsatz",
      days_without_sales: "Tage ohne Verkäufe",
      last_sales_date: "Letztes Verkaufsdatum",
      last_sales_volume: "Letzte Verkaufsmenge",
      last_shipment_date: "Letzter Liefertermin",
      last_shipment_volume: "Letzte Liefermenge",
      promo_start: "Promo-Start",
      promo_end: "Promo-Ende",
      promo_period: "Promo-Zeitraum",
      is_promo: "Promo",
      title: "Name",
      category_name3: "Abschnitt",
      category_name4: "Kategorie"
    },
    hideReadyTasks: "Erledigte Aufgaben ausblenden",
    nothingFound: "Nichts gefunden",
    shopsNotFound: "Geschäfte nicht gefunden",
    chooseShop: "Ein Geschäft auswählen",
    createTasks: "Aufgaben erstellen",
    taskCreating: "Aufgaben werden erstellt",
    next: "Weiter",
    columns: "Spalten",
    from: "Von",
    ok: "OK",
    cancel: "Abbrechen",
    filter: "Filter",
    chooseValue: "Wert auswählen",
    enterValue: "Wert eingeben",
    rangeFrom: "Von",
    rangeTo: "Bis",
    selected: "Ausgewählt",
    foundRows: "Gefundene Zeilen",
    createdTasksTotal: "Insgesamt erstellte Aufgaben"
  }
};
