export default {
  mobileFirefoxAlert: 'Í þessum vafra er verkefnisvinna ekki í boði.',
  datePicker: {
    from: 'Frá',
    to: 'Til',
    cancel: 'Hætta við',
    apply: 'Senda',
    month: [
      'Janúar',
      'Febrúar',
      'Mars',
      'Apríl',
      'Maí',
      'Júní',
      'Júlí',
      'Ágúst',
      'September',
      'Október',
      'Nóvember',
      'Desember'
    ],
    week: 'vika',
    weekShot: 'v',
    buttonName: {
      date: 'Dagsetning',
      week: 'Vika',
      month: 'Mánuður'
    },
    modal: {
      header: 'Aðvörun',
      text: 'Ógild dagsetningarsvið valin',
      action: 'Samþykkja'
    }
  },
  filters: {
    name: 'Nafn'
  },
  scenario: {
    noDataReceived: 'Engin gögn móttekin',
    filterPart: {
      name: 'Nafn',
      clear: 'Hreinsa',
      notFound: 'Ekkert fundið',
      search: 'Leita...'
    }
  },
  header: {
    logout: 'Útskrá',
    changePassword: 'Breyta lykilorði',
    settings: 'Stillingar',
    title: 'Skoða notendaviðmót',
    searchTitle: 'Leita að notanda',
    input: 'Byrjaðu að skrifa nafn eða notendanafn...',
    cancel: 'Hætta við',
    view: 'Skoða',
    id: 'Auðkenni',
    login: 'Notendanafn',
    name: 'Nafn',
    phone: 'Sími',
    email: 'Netfang',
    stopSettings: 'Stöðva skoðun'
  },
  directTask: {
    addCase: 'Bæta við máli',
    commentCase: 'athugasemdMáls',
    actions: 'Aðgerðir',
    searchProduct: 'PLU/Strekkód',
    searchProductShort: 'PLU/Strekkód',
    photo: 'Ljósmynd',
    back: 'Til baka',
    treeEmpty: 'AÐGERÐIR EKKI TIL',
    step: 'Skref',
    sortingButton: 'Raða eftir',
    sortButton: 'Raða',
    add: 'Bæta við',
    delete: 'Eyða',
    deleteManualCases: 'Eyða handvirkum málum',
    show: 'Sýna',
    hide: 'Fela',
    comment: 'Athugasemd',
    showDoneCases: 'Sýna unnin verkefni',
    hideDoneCases: 'Fela unnin verkefni'
  },
  gridTable: {
    pluList: 'PLU-listi'
  },
  casesForm: {
    product: 'Vara',
    loadFoto: 'Hlaða mynd',
    plu: 'Nafn/PLU',
    success: 'Máli bætt við',
    delete: 'Eyða',
    add: 'Búa til',
    new: 'Nýtt',
    cancel: 'Hætta við',
    comment: 'Athugasemd'
  },
  pagination: {
    apply: 'Fara',
    placeholder: 'Síða'
  },
  restorePass: {
    noLength: 'Lykilorð verður að vera a.m.k. 12 stafir',
    noLowercase: 'Lykilorð verður að innihalda a.m.k. einn lítinn staf',
    noUppercase: 'Lykilorð verður að innihalda a.m.k. einn hástaf',
    noNumber: 'Lykilorð verður að innihalda a.m.k. eina tölustaf',
    noMatch: 'Lykilorð stemma ekki',
    password: 'Lykilorð',
    repeatPassword: 'Endurtaktu lykilorð',
    ok: 'Samþykkja',
    cancel: 'Hætta við'
  },
  navigation: {
    missions: 'Verkefni',
    scenarios: 'Hugsanleg aðstæður'
  },
  changePassword: {
    previousPassword: 'Núverandi lykilorð',
    newPassword: 'Nýtt lykilorð',
    confirmPassword: 'Staðfesta nýtt lykilorð',
    apply: 'Senda',
    title: 'Breyta lykilorði',
    success: 'Lykilorð breytt með góðum árangri',
    wrongPassword: 'Rangt lykilorð',
    weakPassword: 'Lykilorð uppfyllir ekki kröfur',
    passwordsNotMatch: 'Lykilorð stemma ekki',
    requirements: 'Nýtt lykilorð verður að vera a.m.k. 12 stafir, með a.m.k. einum há- og lágstaf, og eina tölustaf'
  },
  auth: {
    login: 'Skrá inn',
    password: 'Lykilorð',
    restorePass: 'Endurheimta lykilorð',
    submit: 'Senda',
    loginError: 'Notendanafn og/eða lykilorð eru rangt'
  },
  restorePassForm: {
    restore: 'Endurheimta',
    cancel: 'Hætta við',
    info: 'Vinsamlegast sláðu inn netfangið sem skráð er í GROSA',
    emailInvalid: 'Netfangið sem var slegið inn fannst ekki',
    email: 'Netfang',
    success: 'Tengill til að endurheimta hefur verið sendur á netfangið þitt'
  },
  search: {
    notFound: 'Ekkert fannst'
  },
  noMatchPage: {
    title: 'Fannst ekki beiðnið síða',
    body: 'Fara til baka á forsíðu'
  },
  warnings: {
    title: 'Aðvörun',
    isDesktop: 
      'Myndavél er aðeins í boði í útgáfu fyrir farsíma eða spjaldtölvur. Vinsamlegast notaðu myndavél á síma eða spjaldtölvu.'
  },
  errors: {
    netErrorText: 'Tengingarvilla. Vinsamlegast endurhladdu síðunni',
    netErrorReload: 'Endurhlaða',
    netErrorTitle: 'Villa',
    netErrorClose: 'Loka',
    noShops: 'Úps! Það lítur út fyrir að engum verslunum hafi verið úthlutað þér ennþá. Segðu yfirmanni þínum frá því.',
    noCases: 'Svo virðist sem þjónustan okkar hafi ekki fundið eitt einasta mál í þessari verslun. Byrjaðu að búa til þær sjálfur',
    noAutoOrder: 'Það eru engin verkefni til að stilla sjálfvirka röð',
    causeError: 'Villa',
    scenariosEmpty: 'Fylki hugsanlegra aðstæðna er tómt'
  },
  officeUI: {
    resetZoom: 'Endurstilla zoom'
  },
  gridAutocomplete: {
    placeholder: 'Byrjaðu að skrifa nafn verslunar',
    notFound: 'Verslun fannst ekki'
  },
  targets: {
    nothing: '',
    shop: 'Verslun',
    category: 'Flokkur',
    supplier: 'Distribútor',
    vendor: 'Seljandi',
    brand: 'Vörumerki',
    plu: 'PLU',
    day: 'Dagur',
    week: 'Vika',
    month: 'Mánuður',
    quarter: 'Ársfjórðungur',
    year: 'Ár'
  },
  bubbleChart: {
    qty: 'stk',
    currency: 'kr.'
  },
  chart: {
    quarter: 'Ársfjórðungur',
    week: 'Vika'
  },
  download: {
    CSV: 'Sækja CSV',
    XLS: 'Sækja XLS'
  },
  counterTask: {
    task: 'Ólokið verkefni',
    noTask: 'Engin verkefni'
  },
  filtersShop: {
    shopName: 'Heimilisfang verslunar',
    shopId: 'Nafn verslunar'
  },
  chartCaption: {
    thousand: 'þús.',
    million: 'millj.',
    billion: 'miliard'
  },
  common: {
    close: 'Loka',
    userNotFound: 'Notandi fannst ekki',
    yes: 'Já',
    no: 'Nei'
  },
  virtualStock: {
    headers: {
      case_id: 'Auðkenni',
      plu_original: 'PLU',
      plu: 'Innlent PLU',
      shop_id: 'Auðkenni verslunar',
      ass_type: 'Flokkur af vörum',
      category_id3: 'Auðkenni flokks',
      category_id4: 'Auðkenni flokks',
      stock: 'Magn af eftirverslun',
      stock_sum: 'Heildarmagn',
      product_turnover: 'Verslunartíðni',
      days_without_sales: 'Dagar án sala',
      last_sales_date: 'Dagsetning síðustu sölu',
      last_sales_volume: 'Síðasta sölumagn',
      last_shipment_date: 'Síðasta afhendingarmagnr',
      last_shipment_volume: 'Magn síðustu sendingar',
      promo_start: 'Byrjun á boði',
      promo_end: 'Endir á boði',
      promo_period: 'Lengd boðs',
      is_promo: 'Boð',
      title: 'Nafn',
      category_name3: 'Flokkur 3',
      category_name4: 'Flokkur 4'
    },
    hideReadyTasks: 'Fela búin verkefni',
    nothingFound: 'Ekkert fannst',
    shopsNotFound: 'Engar verslanir fundust',
    chooseShop: 'Veldu verslun',
    createTasks: 'Búa til verkefni',
    taskCreating: 'Búa til verkefni',
    next: 'Áfram',
    columns: 'Dálkar',
    from: 'Frá',
    ok: 'OK',
    cancel: 'Hætta við',
    filter: 'Sía',
    chooseValue: 'Veldu gildi',
    enterValue: 'Sláðu inn gildi',
    rangeFrom: 'Frá',
    rangeTo: 'Til',
    selected: 'Valið',
    foundRows: 'Fjöldi fundna lína',
    createdTasksTotal: 'Heildarfjöldi búinna verkefna'
  }
};
