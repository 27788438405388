export default {
  mobileFirefoxAlert: 'У овом прегледнику обрада задатака није доступна.',
  datePicker: {
    from: 'Од',
    to: 'До',
    cancel: 'Откажи',
    apply: 'Примени',
    month: [
      'Јануар',
      'Фебруар',
      'Март',
      'Април',
      'Мај',
      'Јун',
      'Јул',
      'Август',
      'Септембар',
      'Октобар',
      'Новембар',
      'Децембар'
    ],
    week: 'недеља',
    weekShot: 'н',
    buttonName: {
      date: 'Датум',
      week: 'Недеља',
      month: 'Месец'
    },
    modal: {
      header: 'Упозорење',
      text: 'Изабран је неважећи опсег датума',
      action: 'Прихвати'
    }
  },
  filters: {
    name: 'Име'
  },
  scenario: {
    noDataReceived: 'Подаци нису примљени',
    filterPart: {
      name: 'Име',
      clear: 'Очисти',
      notFound: 'Није пронађено',
      search: 'Претражи...'
    }
  },
  header: {
    logout: 'Одјава',
    changePassword: 'Промена лозинке',
    settings: 'Подешавања',
    title: 'Погледај кориснички интерфејс',
    searchTitle: 'Претражи корисника',
    input: 'Почните да куцате име или пријаву...',
    cancel: 'Откажи',
    view: 'Поглед',
    id: 'ИД',
    login: 'Пријава',
    name: 'Име',
    phone: 'Телефон',
    email: 'Пошта',
    stopSettings: 'Заустави преглед'
  },
  directTask: {
    addCase: 'Додај слукај',
    commentCase: 'commentCase',
    actions: 'Акције',
    searchProduct: 'PLU/Баркод',
    searchProductShort: 'PLU/Баркод',
    photo: 'Фотографија',
    back: 'Назад',
    treeEmpty: 'АКЦИЈЕ НИСУ ДОСТУПНЕ',
    step: 'Корак',
    sortingButton: 'Сортирање',
    sortButton: 'Сортирај',
    add: 'Додај',
    delete: 'Обриши',
    deleteManualCases: 'Обриши ручне случајеве',
    show: 'Прикажи',
    hide: 'Сакриј',
    comment: 'Коментар',
    showDoneCases: 'Прикажи завршене задатке',
    hideDoneCases: 'Сакријте завршене задатке'
  },
  gridTable: {
    pluList: 'Листа PLU'
  },
  casesForm: {
    product: 'Производ',
    loadFoto: 'Учитај фотографију',
    plu: 'Назив/PLU',
    success: 'Слукај је креиран',
    delete: 'Избриши',
    add: 'Креирај',
    new: 'Нова',
    cancel: 'Отказати',
    comment: 'Коментар'
  },
  pagination: {
    apply: 'Иди',
    placeholder: 'Страница'
  },
  restorePass: {
    noLength: 'Лозинка треба да има најмање 12 знакова',
    noLowercase: 'Лозинка треба да има најмање једно слово малогреско',
    noUppercase: 'Лозинка треба да има најмање једно слово великогреско',
    noNumber: 'Лозинка треба да има најмање један бројчани знак',
    noMatch: 'Лозинке се не подударају',
    password: 'Лозинка',
    repeatPassword: 'Поновите лозинку',
    ok: 'Прихвати',
    cancel: 'Откажи'
  },
  navigation: {
    missions: 'Мисије',
    scenarios: 'Сценарији'
  },
  changePassword: {
    previousPassword: 'Текућа лозинка',
    newPassword: 'Нова лозинка',
    confirmPassword: 'Поновите нову лозинку',
    apply: 'Примени',
    title: 'Промена лозинке',
    success: 'Лозинка је успешно промењена',
    wrongPassword: 'Погрешна лозинка',
    weakPassword: 'Лозинка не одговара захтевима',
    passwordsNotMatch: 'Лозинке се не подударају',
    requirements: 'Нова лозинка треба да има најмање 12 знакова, 1 велико и мало слово и 1 број '
  },
  auth: {
    login: 'Пријава',
    password: 'Лозинка',
    restorePass: 'Понови лозинку',
    submit: 'Поднеси',
    loginError: 'Пријава и/или лозинка нису исправни'
  },
  restorePassForm: {
    restore: 'Понови',
    cancel: 'Откажи',
    info: 'Молимо унесите своју е-пошту, која је регистрована на GROSA',
    emailInvalid: 'Поштанска адреса није пронађена',
    email: 'Е-пошта',
    success: 'Линк за поново активирање је послат на вашу е-пошту'
  },
  search: {
    notFound: 'Није пронађено'
  },
  noMatchPage: {
    title: 'Тражена страница није пронађена',
    body: 'Врати се на почетну страницу'
  },
  warnings: {
    title: 'Упозорење',
    isDesktop: 'Учитавање фотографија је доступно само у мобилној верзији система. Користите камеру на свом телефону или таблету, молимо.'
  },
  errors: {
    netErrorText: 'Мрежна грешка. Молимо, освежите страницу',
    netErrorReload: 'Освежи',
    netErrorTitle: 'Грешка',
    netErrorClose: 'Затвори',
    noShops: 'ОХ! Изгледа да вам још увек није додељена ниједна продавница. Реците свом менаџеру о томе',
    noCases: 'Чини се да наша служба није успела да пронађе ни једну кутију у овој продавници. Почните сами да их креирате',
    noAutoOrder: 'Нема задатака за подешавање аутоматског поретка',
    causeError: 'Грешка',
    scenariosEmpty: 'Низ сценарија је празан'
  },
  officeUI: {
    resetZoom: 'Ресетуј зум'
  },
  gridAutocomplete: {
    placeholder: 'Почните да куцате име продавнице',
    notFound: 'Продавница није пронађена'
  },
  targets: {
    nothing: '',
    shop: 'Продавница',
    category: 'Категорија',
    supplier: 'Добављач',
    vendor: 'Продавац',
    brand: 'Бренд',
    plu: 'PLU',
    day: 'Дан',
    week: 'Недеља',
    month: 'Месец',
    quarter: 'Квартал',
    year: 'Година'
  },
  bubbleChart: {
    qty: 'кол.',
    currency: 'руб'
  },
  chart: {
    quarter: 'квартал',
    week: 'недеља'
  },
  download: {
    CSV: 'Преузми CSV',
    XLS: 'Преузми XLS'
  },
  counterTask: {
    task: 'Нерешени задаци',
    noTask: 'Нема задатака'
  },
  filtersShop: {
    shopName: 'Адреса продавнице',
    shopId: 'Име продавнице'
  },
  chartCaption: {
    thousand: 'хиљ.',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Затвори',
    userNotFound: 'Корисник није пронађен',
    yes: 'Да',
    no: 'Не',
  },
  virtualStock: {
    headers: {
      case_id: "ИД",
      plu_original: "PLU",
      plu: "Интерни PLU",
      shop_id: "ИД продавнице",
      ass_type: "Тип асортимана",
      category_id3: "ИД секције",
      category_id4: "ИД категорије",
      stock: "Запремина залиха",
      stock_sum: "Износ баланса",
      product_turnover: "Обрт",
      days_without_sales: "Дани без продаже",
      last_sales_date: "Датум последње продаје",
      last_sales_volume: "Последњи обим продаје",
      last_shipment_date: "Последњи датум испоруке",
      last_shipment_volume: "Последњи обим испоруке",
      promo_start: "Почетак промоције",
      promo_end: "Завршетак промоције",
      promo_period: "Трајање промоције",
      is_promo: "Промо",
      title: "Име",
      category_name3: "Секција",
      category_name4: "Категорија"
    },
    hideReadyTasks: "Сакриј готове задатке",
    nothingFound: "Ништа није пронађено",
    shopsNotFound: "Продавнице нису пронађене",
    chooseShop: "Изаберите продавницу",
    createTasks: "Креирање задатака",
    taskCreating: "Креирање задатака",
    next: "Настави",
    columns: "Колоне",
    from: "Од",
    ok: "У реду",
    cancel: "Откажи",
    filter: "Филтер",
    chooseValue: "Изаберите вредност",
    enterValue: "Унесите вредност",
    rangeFrom: "Од",
    rangeTo: "До",
    selected: "Изабрано",
    foundRows: "Пронађено редова",
    createdTasksTotal: "Укупно креирани задаци"
  }
};
