import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem, ListItemAvatar, ListItemText, TextField, withStyles } from '@material-ui/core';
import { AccountCircle, Edit } from '@material-ui/icons';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SettingsIcon from '@material-ui/icons/Settings';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import history from '../../config/history';
import { addUserData, showKpiDebugAction, toggleImpersonation } from '../../store/actions/actions';
import configAxios from '../../config/axios';

import styles from './Header.css';


const style = {
  MuiDialogContent: {
    overflow : 'hidden'
  },
  accCircle1: {
    cursor: 'pointer',
    color: '#398bdf',
    margin: 0,
    fontSize: 48,
    width: 48,
    height: 48,
    position: 'relative'
  },
  accCircle2: {
    fontSize: 48,
    width: 48,
    height: 48
  },
  iconsColor: {
    color: '#b3b3b3',
    paddingRight: "10px"
  },
  settingsButton: {
    border: "1px solid #58cffd",
    borderRadius: "3px",
    opacity: "1",
    marginLeft: "auto",
    padding: "10px 15px",
    cursor: "pointer",
    margin: "10px"
  },
  viewButton: {
    border: "1px solid #58cffd",
    fontSize: "1em",
    borderRadius: "3px",
    opacity: "1",
    marginLeft: "auto",
    padding: "10px 15px",
    cursor: "pointer",
    margin: "10px",
    color: "white",
    backgroundColor: "#7FC4EA"
  },
  viewButtonDisabled: {
    border: "1px solid #CBCBCB",
    borderRadius: "3px",
    fontSize: "1em",
    opacity: "1",
    marginLeft: "auto",
    padding: "10px 15px",
    cursor: "pointer",
    margin: "10px",
    color: "#ffffff",
    backgroundColor: "#CBCBCB"
  },
  paperWidthSm: {
    minWidth: "1000px"
  },
  searchTitle: {
    fontWeight: 300,
    color: "rgb(0 0 0 / 86%)",
    margin: 0
  },
  title: {
    textAlign: "center"
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer"
  }
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openProfile: false,
      anchorEl: null,
      open: false,
      suggest: [],
      selectedSuggest : null,
      value: ""
    };
    this.inputRef = React.createRef(null);
  }
  
  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  suggest = params => {
    const { userData, language: { translation } } = this.props;
    this.setState({
      value: params
    });

    if (params.length > 3) {
      configAxios({
        method: 'GET',
        url: `/user/impersonate/search?search=${params}`,
      }).then(response => {
        let suggestResponseCopy = response.data.data;
        if(suggestResponseCopy !== null) {
          if(Array.isArray(suggestResponseCopy)){
            suggestResponseCopy = suggestResponseCopy
              .filter(user => Number(userData.id) !== Number(user.id))
              .map(user => ({ ...user, name : user.name + ' ', login : user.login + ' ' }));
          }
          this.setState({
            suggest: suggestResponseCopy,
          });
        } 
        else {
          this.setState({
            suggest: [{ name: translation.common.userNotFound, status: true }],
          });
        }
      });
    } else {
      this.setState({
        suggest: [],
      });
    }
  };

  selectSuggested = value => {
    const { suggest, selectedSuggest } = this.state;
    console.log('selectSuggested', value, suggest, selectedSuggest);

    if((suggest.length === 1 && suggest[0].status) || value == null){
      this.setState({
        selectedSuggest : null
      });
    } else {
      const selected = suggest.find(sug => (sug.name === value || sug.login === value) && sug.status === undefined ) || null;
      if(!_.isEqual(selected, selectedSuggest) && selected !== null){
        console.log(suggest, selectedSuggest);
    
        this.setState({
          selectedSuggest : selected
        });
      } 
    }
  }

  onFocus = () => {
    const storageStatus = localStorage.getItem("userData");
    if(!storageStatus) {
      this.handleLogout();
    }
  }

  handleLogout = () => {
    const { dispatch } = this.props;
    const redashCookies = localStorage.getItem("redashCookies");
    const virtualStockTableHeadersOrder = localStorage.getItem("virtualStockTableHeadersOrder");
    localStorage.clear();
    localStorage.setItem("redashCookies", redashCookies);
    localStorage.setItem("virtualStockTableHeadersOrder", virtualStockTableHeadersOrder);
    dispatch(addUserData({}));
    history.push('/');
  }
  changePassword = () => {
    this.setState({
      openProfile: false
    });
    history.push('/change-password');
  };

  openProfile = e => {
    this.setState({
      openProfile: true,
      anchorEl: e.currentTarget
    });
  };

  profileCloseHandle = () => {
    this.setState({
      openProfile: false,
      anchorEl: null
    });
  };

  showKpiDebug = () => {
    const { dispatch } = this.props;
    dispatch(showKpiDebugAction(true));
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    }, () =>{
      setTimeout(() => {
        this.inputRef.focus();
      }, 100);
    });
  };

  handleStartImpersonation = () => {
    configAxios({
      method: 'POST',
      url: `/user/impersonate/start`,
      data: {
        id: this.state.suggest[0].id
      }
    }).then(res => {
      localStorage.setItem('impersonation', res.headers.impersonation); 
      this.props.dispatch(toggleImpersonation(true));
      this.setState({
        open: false,
        suggest: [],
        value: '',
        openProfile: false
      }, () => {
        if (location.pathname !== '/') {
          history.push('/');
        }
      });
    }).catch(err =>{
      console.log(err);
    });
  } 

  handleStopImpersonation = () => {
    configAxios({
      method: 'POST',
      url: `user/impersonate/stop`,
    }).then(() => {
      localStorage.removeItem('impersonation'); 
      localStorage.removeItem('missions'); 
      this.props.dispatch(toggleImpersonation(false));
      this.setState({
        open: false,
        suggest: [],
        openProfile: false,
      }, () => {
        if (location.pathname !== '/') {
          history.push('/');
        }
      });
    });
  }

  handleClickClose = () => {
    this.setState({
      open: false,
      suggest: [],
      value : "",
      openProfile: false,
      selectedSuggest: null
    });
  };

  render() {
    const {
      userData,
      kpiDebug,
      classes,
      impersonation,
      language: { translation }
    } = this.props;
    const { openProfile, anchorEl, suggest, value, selectedSuggest } = this.state;
    const userName = userData.name;
    const companyName = userData.company ? userData.company.name : '';
    const isSuggested = Boolean(selectedSuggest);
    const userHasImpersonation = JSON.parse(localStorage.getItem('userHasImpersonation'));
    return (
      <header>
        <div className={styles.container}>
          <Grid container className={styles.headerWrap}>
            <Grid item xs={6}>
              <div className={styles.logoWrap}>
                <Link className={styles.logo} to="/">
                  <img src="/img/Logo_HP_white_2.svg" alt="Logo" width="176" />
                </Link>
                {kpiDebug.data.length > 0 && (
                  <div className={styles.invisibleOne} role="presentation" onClick={this.showKpiDebug} />
                )}
              </div>
            </Grid>

            <Grid item xs={6} className={styles.profileWrap}>
              <div className={styles.userData}>
                {userName && <div className={styles.userDataName}>{userName}</div>}
                {companyName && <div className={styles.userDataCompany}>{companyName}</div>}
              </div>

              <div className={styles.profile}>
                <div className={styles.avatarWrap}>
                  <div className={styles.avatar} />
                  <AccountCircle className={classes.accCircle1} onClick={e => this.openProfile(e)} />
                </div>
                <Popover
                  open={openProfile}
                  anchorEl={anchorEl}
                  onClose={this.profileCloseHandle}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <div className={styles.personalInfoBlock}>
                    <div className={styles.personalInfoBlockWrap}>
                      <ListItem className={styles.personalInfoItem}>
                        <ListItemAvatar>
                          <Avatar className={styles.avatarSvg}>
                            <AccountCircle className={classes.accCircle2} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography
                          primary={<div className={styles.userName}>{userName}</div>}
                          secondary={<div className={styles.companyName}>{companyName}</div>}
                        />
                      </ListItem>
                      <div className={cls(styles.logoutWrap, styles.cf)}>
                       {userHasImpersonation ? 
                         impersonation ? 
                            <div className={cls(styles.button)}>
                              <CloseIcon className={classes.iconsColor} />
                              <button className={styles.exitText} onClick={this.handleStopImpersonation}>{translation.header.stopSettings}</button>
                            </div>
                           :
                            <div className={cls(styles.button)}>
                              <SettingsIcon className={classes.iconsColor} />
                              <button className={styles.exitText} onClick={this.handleClickOpen}>{translation.header.settings}</button>
                            </div>
                         : null
                       } 
                        <button className={cls(styles.button, styles.changePassword)} onClick={this.changePassword}>
                          <Edit className={classes.iconsColor} />
                          <div className={styles.exitText}>{translation.header.changePassword}</div>
                        </button>
                        <button className={cls(styles.button, styles.logout)} onClick={this.handleLogout}>
                          <PowerSettingsNewIcon className={classes.iconsColor} />
                          <div className={styles.exitText}>{translation.header.logout}</div>
                        </button>
                        <Dialog
                          open={this.state.open}
                          onExited={this.handleClickClose}
                          maxWidth="sm"
                          fullWidth
                        > 
                          <CloseIcon className={classes.closeIcon} onClick={this.handleClickClose} />
                          <DialogTitle className={classes.title}>{translation.header.title}</DialogTitle>
                          <DialogContent className={classes.MuiDialogContent}>
                            <DialogContentText className={classes.searchTitle}>
                              {translation.header.searchTitle}
                            </DialogContentText>
                            <Autocomplete
                              freeSolo
                              size="small"
                              value={value}
                              options={suggest.map(options => options.name)}
                              onInputChange={(event, value) => this.suggest(value)}
                              onChange={(event, value) => this.selectSuggested(value)}
                              renderInput={params => (
                                <TextField 
                                  {...params} 
                                  inputRef={input => { this.inputRef = input; }} 
                                  label={translation.header.input} 
                                  margin="normal" 
                                  variant="outlined" 
                                />
                              )}
                            />
                              {isSuggested &&
                                <table className={styles.viewTable}>
                                  <tbody>
                                    {Object.keys(selectedSuggest).map(item => {
                                      if(item !== "first_login") {
                                        return (
                                          <tr className={styles.row} key={item}>
                                            <td className={styles.left}>{translation.header[item]}</td>
                                            <td className={styles.right}>{selectedSuggest[item]}</td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </tbody>
                                </table>}
                            
                          </DialogContent>
                          <DialogActions>
                            <button className={classes.settingsButton} onClick={this.handleClickClose}>
                              {translation.header.cancel}
                            </button>
                            {isSuggested ? 
                              <button className={classes.settingsButton} onClick={this.handleStartImpersonation}>
                                {translation.header.view}
                              </button> :
                              <button className={classes.settingsButton} disabled>
                                {translation.header.view}
                              </button>
                            }
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                </Popover>
              </div>
            </Grid>
          </Grid>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  userData: {}
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  language: PropTypes.object.isRequired,
  kpiDebug: PropTypes.object.isRequired,
  userData: PropTypes.object,
  impersonation: PropTypes.bool,

};

export default connect(store => {
  return {
    userData: store.userData,
    kpiDebug: store.kpiDebug,
    language: store.language,
    impersonation: store.impersonation
  };
})(withStyles(style)(Header));
