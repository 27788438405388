export default {
  mobileFirefoxAlert: 'Bu tarayıcıda görev işleme mevcut değil.',
  datePicker: {
    from: 'Başlangıç',
    to: 'Bitiş',
    cancel: 'İptal',
    apply: 'Uygula',
    month: [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık'
    ],
    week: 'hafta',
    weekShot: 'h',
    buttonName: {
      date: 'Tarih',
      week: 'Hafta',
      month: 'Ay'
    },
    modal: {
      header: 'Uyarı',
      text: 'Geçersiz tarih aralığı seçildi',
      action: 'Kabul'
    }
  },
  filters: {
    name: 'Ad'
  },
  scenario: {
    noDataReceived: 'Veri alınmadı',
    filterPart: {
      name: 'Ad',
      clear: 'Temizle',
      notFound: 'Bulunamadı',
      search: 'Ara...'
    }
  },
  header: {
    logout: 'Çıkış yap',
    changePassword: 'Şifre değiştir',
    settings: 'Ayarlar',
    title: 'Kullanıcı arayüzünü görüntüle',
    searchTitle: 'Kullanıcı ara',
    input: 'Ad veya giriş yazmaya başla...',
    cancel: 'İptal',
    view: 'Görüntüle',
    id: 'ID',
    login: 'Giriş',
    name: 'Ad',
    phone: 'Telefon',
    email: 'E-posta',
    stopSettings: 'Görüntülemeyi durdur'
  },
  directTask: {
    addCase: 'Vaka ekle',
    commentCase: 'Yorum ekle',
    actions: 'Eylemler',
    searchProduct: 'PLU/Barkod ara',
    searchProductShort: 'PLU/Barkod',
    photo: 'Fotoğraf',
    back: 'Geri',
    treeEmpty: 'EYLEMLER KULLANIM DIŞI',
    step: 'Adım',
    sortingButton: 'Sıralama',
    sortButton: 'Sırala',
    add: 'Ekle',
    delete: 'Sil',
    deleteManualCases: 'Manuel vakaları sil',
    show: 'Göster',
    hide: 'Gizle',
    comment: 'Yorum',
    showDoneCases: 'Tamamlanan görevleri göster',
    hideDoneCases: 'Tamamlanan görevleri gizle'
  },
  gridTable: {
    pluList: 'PLU listesi'
  },
  casesForm: {
    product: 'Ürün',
    loadFoto: 'Fotoğraf Yükle',
    plu: 'Ad/PLU',
    success: 'Vaka oluşturuldu',
    delete: 'Silmek',
    add: 'Yaratmak',
    new: 'Yeni',
    cancel: 'İptal',
    comment: 'Yorum'
  },
  pagination: {
    apply: 'Git',
    placeholder: 'Sayfa'
  },
  restorePass: {
    noLength: 'Şifre en az 12 karakter olmalıdır',
    noLowercase: 'Şifrede en az bir küçük harf olmalıdır',
    noUppercase: 'Şifrede en az bir büyük harf olmalıdır',
    noNumber: 'Şifrede en az bir sayı sembolü olmalıdır',
    noMatch: 'Şifreler uyuşmuyor',
    password: 'Şifre',
    repeatPassword: 'Şifreyi Tekrarla',
    ok: 'Kabul',
    cancel: 'İptal'
  },
  navigation: {
    missions: 'Görevler',
    scenarios: 'Senaryolar'
  },
  changePassword: {
    previousPassword: 'Mevcut şifre',
    newPassword: 'Yeni şifre',
    confirmPassword: 'Yeni şifreyi onayla',
    apply: 'Uygula',
    title: 'Şifre değiştir',
    success: 'Şifre başarıyla değiştirildi',
    wrongPassword: 'Yanlış şifre',
    weakPassword: 'Şifre gereklilikleri uyumlu değil',
    passwordsNotMatch: 'Şifreler uyuşmuyor',
    requirements: 
      'Yeni şifre en az 12 karakter, 1 büyük ve küçük harf ve 1 sayı içermelidir'
  },
  auth: {
    login: 'Giriş',
    password: 'Şifre',
    restorePass: 'Şifreyi sıfırla',
    submit: 'Gönder',
    loginError: 'Kullanıcı adı ve/veya şifre geçersiz'
  },
  restorePassForm: {
    restore: 'Sıfırla',
    cancel: 'İptal',
    info: 'Lütfen GROSA\'da kayıtlı olan e-posta adresinizi girin',
    emailInvalid: 'Girilen e-posta bulunamadı',
    email: 'E-posta',
    success: 'Sıfırlama bağlantısı e-postanıza gönderildi'
  },
  search: {
    notFound: 'Bulunamadı'
  },
  noMatchPage: {
    title: 'İstenilen sayfa bulunamadı',
    body: 'Ana sayfaya dön'
  },
  warnings: {
    title: 'Uyarı',
    isDesktop: 'Fotoğraf yükleme sadece sistemin mobil sürümünde mümkündür. Lütfen telefonunuzun veya tabletinizin kamerasını kullanın.'
  },
  errors: {
    netErrorText: 'Ağ hatası. Lütfen, sayfayı yeniden yükleyin',
    netErrorReload: 'Yeniden Yükle',
    netErrorTitle: 'Hata',
    netErrorClose: 'Kapat',
    noShops: 'Ayy! Henüz size hiçbir mağaza atanmamış gibi görünüyor. Yöneticinize bundan bahsedin',
    noCases: 'Görünüşe göre servisimiz bu mağazada tek bir vaka bulamadı. Bunları kendiniz oluşturmaya başlayın.',
    noAutoOrder: 'Otomatik sipariş düzeltilmesi için görev yok.',
    causeError: 'Hata',
    scenariosEmpty: 'Senaryo dizisi boş'
  },
  officeUI: {
    resetZoom: 'Yakınlaşmayı Sıfırla'
  },
  gridAutocomplete: {
    placeholder: 'Mağaza adını yazmaya başlayın',
    notFound: 'Mağaza bulunamadı'
  },
  targets: {
    nothing: '',
    shop: 'Mağaza',
    category: 'Kategori',
    supplier: 'Tedarikçi',
    vendor: 'Satıcı',
    brand: 'Marka',
    plu: 'PLU',
    day: 'Gün',
    week: 'Hafta',
    month: 'Ay',
    quarter: 'Çeyrek',
    year: 'Yıl'
  },
  bubbleChart: {
    qty: 'miktar',
    currency: '₺'
  },
  chart: {
    quarter: 'çeyrek',
    week: 'hafta'
  },
  download: {
    CSV: 'CSV İndir',
    XLS: 'XLS İndir'
  },
  counterTask: {
    task: 'Bekleyen görevler',
    noTask: 'Görev yok'
  },
  filtersShop: {
    shopName: 'Mağaza adresi',
    shopId: 'Mağaza adı'
  },
  chartCaption: {
    thousand: 'bin',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Kapat',
    userNotFound: 'Kullanıcı bulunamadı',
    yes: 'Evet',
    no: 'Hayır',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "İç PLU",
      shop_id: "Mağaza ID",
      ass_type: "Assorti Türü",
      category_id3: "Bölüm ID",
      category_id4: "Kategori ID",
      stock: "Stok hacmi",
      stock_sum: "Bakiye miktarı",
      product_turnover: "Dönüşüm",
      days_without_sales: "Satış yapılmayan günler",
      last_sales_date: "Son satış tarihi",
      last_sales_volume: "Son satış hacmi",
      last_shipment_date: "Son sevkiyat tarihi",
      last_shipment_volume: "Son sevkiyat hacmi",
      promo_start: "Promosyon Başlangıcı",
      promo_end: "Promosyon Sonu",
      promo_period: "Promosyon Süresi",
      is_promo: "Promosyon",
      title: "Ad",
      category_name3: "Bölüm",
      category_name4: "Kategori"
    },
    hideReadyTasks: "Tamamlanan görevleri gizle",
    nothingFound: "Hiçbir Şey Bulunamadı",
    shopsNotFound: "Mağazalar bulunamadı",
    chooseShop: "Bir mağaza seç",
    createTasks: "Görevler oluştur",
    taskCreating: "Görevler oluşturuluyor",
    next: "Devam",
    columns: "Sütunlar",
    from: "Başlangıç",
    ok: "Tamam",
    cancel: "İptal",
    filter: "Filtre",
    chooseValue: "Bir değer seç",
    enterValue: "Değer girin",
    rangeFrom: "Başlangıç",
    rangeTo: "Bitiş",
    selected: "Seçilen",
    foundRows: "Bulunan satırlar",
    createdTasksTotal: "Toplam oluşturulan görevler"
  }
};
