export default {
  mobileFirefoxAlert: 'In hierdie webblaaier is taakverwerking nie beskikbaar nie.',
  datePicker: {
    from: 'Van',
    to: 'Tot',
    cancel: 'Kanselleer',
    apply: 'Toepas',
    month: [
      'Januarie',
      'Februarie',
      'Maart',
      'April',
      'Mei',
      'Junie',
      'Julie',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'Desember'
    ],
    week: 'week',
    weekShot: 'w',
    buttonName: {
      date: 'Datum',
      week: 'Week',
      month: 'Maand'
    },
    modal: {
      header: 'Waarskuwing',
      text: 'Ongeldige datumbereik gekies',
      action: 'Aanvaar'
    }
  },
  filters: {
    name: 'Naam'
  },
  scenario: {
    noDataReceived: 'Geen data ontvang nie',
    filterPart: {
      name: 'Naam',
      clear: 'Duidelik',
      notFound: 'Nie gevind nie',
      search: 'Soek...'
    }
  },
  header: {
    logout: 'Teken uit',
    changePassword: 'Verander wagwoord',
    settings: 'Verifikasie',
    title: 'Bekyk gebruikerskoppelvlak',
    searchTitle: 'Soek gebruiker',
    input: 'Begin tik naam of login...',
    cancel: 'Kanselleer',
    view: 'Bekyk',
    id: 'ID',
    login: 'Aanteken',
    name: 'Naam',
    phone: 'Telefoon',
    email: 'E-pos',
    stopSettings: 'Stop aanskouing'
  },
  directTask: {
    addCase: 'Voeg saak by',
    commentCase: 'commentCase',
    actions: 'Aksies',
    searchProduct: 'PLU/Kode',
    searchProductShort: 'PLU/Kode',
    photo: 'Foto',
    back: 'Terug',
    treeEmpty: 'AKSIES IS NIE BESKIKBAAR NIE',
    step: 'Stap',
    sortingButton: 'Sortering',
    sortButton: 'Sorteer',
    add: 'Voeg by',
    delete: 'Verwyder',
    deleteManualCases: 'Verwyder handmatige gevalle',
    show: 'Wys',
    hide: 'Versteek',
    comment: 'Opmerking',
    showDoneCases: 'Wys voltooide take',
    hideDoneCases: 'Versteek voltooide take'
  },
  gridTable: {
    pluList: 'PLU-lys'
  },
  casesForm: {
    product: 'Produk',
    loadFoto: 'Laai Foto',
    plu: 'Naam/PLU',
    success: 'Sak geskep',
    delete: 'Vee uit',
    add: 'Skep',
    new: 'Nuut',
    cancel: 'Kanselleer',
    comment: 'Opmerking'
  },
  pagination: {
    apply: 'Gaan',
    placeholder: 'Bladsy'
  },
  restorePass: {
    noLength: 'Wagwoord moet minstens 12 karakters hê',
    noLowercase: 'Wagwoord moet ten minste een kleinletter hê',
    noUppercase: 'Wagwoord moet ten minste een hoofletter hê',
    noNumber: 'Wagwoord moet ten minste een numeriese simbool hê',
    noMatch: 'Wagwoorde stem nie ooreen nie',
    password: 'Wagwoord',
    repeatPassword: 'Herhaal wagwoord',
    ok: 'Aanvaar',
    cancel: 'Kanselleer'
  },
  navigation: {
    missions: 'Missies',
    scenarios: 'Skenarios'
  },
  changePassword: {
    previousPassword: 'Huidige wagwoord',
    newPassword: 'Nuwe wagwoord',
    confirmPassword: 'Bevestig nuwe wagwoord',
    apply: 'Aansoek doen',
    title: 'Verander wagwoord',
    success: 'Wagwoord is suksesvol verander',
    wrongPassword: 'Verkeerde wagwoord',
    weakPassword: 'Wagwoord voldoen nie aan vereistes nie',
    passwordsNotMatch: 'Wagwoorde stem nie ooreen nie',
    requirements: 'Nuwe wagwoord moet minstens 12 karakters hê, een hoofletter en een kleinletter en een nommer'
  },
  auth: {
    login: 'Teken aan',
    password: 'Wagwoord',
    restorePass: 'Herstel wagwoord',
    submit: 'Indien',
    loginError: 'Aanteken en/of wagwoord is ongeldig'
  },
  restorePassForm: {
    restore: 'Herstel',
    cancel: 'Kanselleer',
    info: 'Voer asseblief jou e-pos in wat geregistreer is op die GROSA',
    emailInvalid: 'Ingediende e-pos is nie gevind nie',
    email: 'E-pos',
    success: 'Herstel skakel is gestuur na jou e-pos'
  },
  search: {
    notFound: 'Nie gevind nie'
  },
  noMatchPage: {
    title: 'Versoekte bladsy nie gevind nie',
    body: 'Terugkeer na die tuisblad'
  },
  warnings: {
    title: 'Waarskuwing',
    isDesktop: 'Fotos oplaai is slegs beskikbaar in die mobiele weergawe van die stelsel. Gebruik asseblief die kamera op jou foon of tablet.'
  },
  errors: {
    netErrorText: 'Netwerkfout. Laai asseblief die bladsy weer',
    netErrorReload: 'Herlaai',
    netErrorTitle: 'Fout',
    netErrorClose: 'Maak toe',
    noShops: 'O! Dit lyk of daar nog geen winkels aan jou toegewys is nie. Vertel jou bestuurder daarvan',
    noCases: 'Dit blyk dat ons diens geen take in hierdie winkel kon vind nie. Begin dit self skep',
    noAutoOrder: 'Daar is geen take om die outomatiese volgorde aan te pas nie.',
    causeError: 'Fout',
    scenariosEmpty: 'Skenarios-array is leeg'
  },
  officeUI: {
    resetZoom: 'Herstel zoem'
  },
  gridAutocomplete: {
    placeholder: 'Begin tik winkelnaam',
    notFound: 'Winkel nie gevind nie'
  },
  targets: {
    nothing: '',
    shop: 'Winkel',
    category: 'Kategorie',
    supplier: 'Verskaffer',
    vendor: 'Lewerer',
    brand: 'Handelsmerk',
    plu: 'PLU',
    day: 'Dag',
    week: 'Week',
    month: 'Maand',
    quarter: 'Kwartaal',
    year: 'Jaar'
  },
  bubbleChart: {
    qty: 'hoeveelheid',
    currency: 'rub'
  },
  chart: {
    quarter: 'kwartaal',
    week: 'week'
  },
  download: {
    CSV: 'Laai CSV af',
    XLS: 'Laai XLS af'
  },
  counterTask: {
    task: 'Hangende take',
    noTask: 'Geen take'
  },
  filtersShop: {
    shopName: 'Winkeladres',
    shopId: 'Winkelnaam'
  },
  chartCaption: {
    thousand: 'k',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Maak toe',
    userNotFound: 'Gebruiker nie gevind nie',
    yes: 'Ja',
    no: 'Nee',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "Interne PLU",
      shop_id: "Winkel ID",
      ass_type: "Assortimentstipe",
      category_id3: "Afdeling ID",
      category_id4: "Kategorie ID",
      stock: "Hoeveelheid oorblywende",
      stock_sum: "Bedrag van balanse",
      product_turnover: "Draaiing",
      days_without_sales: "Dae sonder verkope",
      last_sales_date: "Datum van laaste verkoping",
      last_sales_volume: "Laaste verkoopsvolume",
      last_shipment_date: "Laaste afleweringsdatum",
      last_shipment_volume: "Laaste afleweringsvolume",
      promo_start: "Promo Begin",
      promo_end: "Promo Einde",
      promo_period: "Promo Periode",
      is_promo: "Promo",
      title: "Naam",
      category_name3: "Afdeling",
      category_name4: "Kategorie"
    },
    hideReadyTasks: "Versteek gereedskap",
    nothingFound: "Niks gevind nie",
    shopsNotFound: "Winkels nie gevind nie",
    chooseShop: "Kies 'n winkel",
    createTasks: "Skep take",
    taskCreating: "Skep take",
    next: "Gaan voort",
    columns: "Kolomme",
    from: "Van",
    ok: "OK",
    cancel: "Kanselleer",
    filter: "Filtreer",
    chooseValue: "Kies 'n waarde",
    enterValue: "Voer waarde in",
    rangeFrom: "Van",
    rangeTo: "Tot",
    selected: "Gekies",
    foundRows: "Gevindde rye",
    createdTasksTotal: "Totaal geskepde take"
  }
};
