import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';

import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row, Col } from 'react-flexbox-grid';
import cls from 'classnames';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import { loadTask, setPageLoadingStatus } from '../../store/actions/actions';
import configAxios from '../../config/axios';
import Preload from '../../constructors/Preload';
import Comment from '../../components/tableComponents/CommentMobile/CommentMobile';
import Search from '../../components/tableComponents/Search/Search';
import TaskDropdown from '../../components/tableComponents/TaskDropdownMobile/TaskDropdownMobile';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';
import ButtonDefault from '../Buttons/ButtonDefault';

import styles from './MobileTable.css';
import { updatePriceFormat } from '../../utils/utils';

const style = () => ({
  progressSelectedItem: {
    color: '#00A5E6'
  },
  progressStatus: {
    color: '#398bdf',
    verticalAlign: 'middle'
  }
});

class MobileTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoResponse: {},
      infoLoading: true,
      selectedItem: null,
      loadingArray: [],
      cancelRequest: ''
    };

    this.withoutColumns = ['tree', 'image', 'info', 'comment'];
    this.showTalbeCol = ['category_name', 'product_name', 'status'];

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  componentWillReceiveProps(nextProps) {
    const itemIdField = this.props.tableType === 'direct' ? 'item_id' : 'random_plu_id';
    const { loadingArray } = this.state;

    if (nextProps.items !== this.props.items) {
      loadingArray.map(item => {
        nextProps.items.filter(obj => {
          if (obj.item_id === item.item_id) {
            return (obj.tree = 'loading');
          }
        });
      });

      if (this.state.selectedItem) {
        let nextSelectedItem = null;

        nextProps.items.forEach(item => {
          if (item[itemIdField] === this.state.selectedItem[itemIdField]) {
            nextSelectedItem = item;
          }
        });

        this.setState({
          selectedItem: nextSelectedItem
        });
      }
    }
  }

  handleItemOpen = item => {
    const itemIdField = this.props.tableType === 'direct' ? 'item_id' : 'random_plu_id';

    this.setState(
      {
        selectedItem: item,
        infoResponse: {},
        infoLoading: true
      },
      () => {
        this.loadInfoFromServer(item[itemIdField]);
      }
    );
  };

  handleItemClose = () => {
    const { cancelRequest } = this.state;
    cancelRequest();
    this.setState({
      selectedItem: null
    });
  };

  loadInfoFromServer(itemId) {
    const { tableType, urlApi } = this.props;

    if (tableType === 'direct') {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.setState({
        cancelRequest: source.cancel
      });

      const query = new URLSearchParams(location.search);
      const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

      configAxios({
        method: 'POST',
        url: `/${urlApi}/info`,
        cancelToken: source.token,
        data: {
          missionId: mid,
          itemId
        }
      }).then(
        response => {
          this.setState({
            infoLoading: false,
            infoResponse: updatePriceFormat(response.data.data)
          });
        },
        () => {
          this.setState({
            infoLoading: false
          });
        }
      );
    }
  }

  deleteCase = (itemId, caseType) => {
    const { dispatch, shopId } = this.props;
    const aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    const promise = configAxios({
      method: 'DELETE',
      url: `/task/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType,
        actionDateTime: moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss")
      }
    });

    promise.then(function() {
      dispatch(loadTask(true));
    });

    return promise;
  };

  loadActionToServer = formData => {
    const { dispatch, items, tableType, urlApi } = this.props;
    const { loadingArray, selectedItem } = this.state;
    const itemIdField = tableType === 'direct' ? 'item_id' : 'random_plu_id';
    let newLoadingArray = loadingArray;

    const selectedIndex = items.indexOf(selectedItem);
    items[selectedIndex].tree = 'loading';
    const isAutoOrder = urlApi === "auto-order";

    newLoadingArray.push(selectedItem);

    this.setState({
      loadingArray: newLoadingArray
    });
    configAxios({
      method: isAutoOrder ? 'PUT' : 'POST',
      url: isAutoOrder ? `/${urlApi}/${this.state.selectedItem.id}` : `/${urlApi}/update`,
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          const loadingArrayIndex = newLoadingArray.indexOf(selectedItem);
          newLoadingArray.splice(loadingArrayIndex, 1);

          this.setState(
            {
              openMenu: false,
              loadingImg: false,
              loadingArray: newLoadingArray
            },
            () => {
              const selectedItemNew = this.state.selectedItem;
              dispatch(loadTask(true, true));

              if (selectedItemNew && selectedItemNew[itemIdField] === selectedItem[itemIdField]) {
                this.loadInfoFromServer(selectedItemNew[itemIdField]);
              }
            }
          );
        }
      })
      .catch(() => {
        this.setState({
          loadingImg: false,
          imageFile: null
        });
      });
  };
  render() {
    const {
      shopId,
      items = [],
      headers = {},
      suggest = '',
      manualCases,
      openOnlyCases,
      tableType,
      classes,
      language,
      urlApi
    } = this.props;
    const { infoResponse, infoLoading, selectedItem } = this.state;

    const lang = language.translation.directTask;

    const itemIdField = tableType === 'direct' ? 'item_id' : 'random_plu_id';
    const autoOrder = urlApi === 'auto-order';

    const tableRow = (key, field, value) => (
      <Row key={key} between="xs" class={styles.selectedCaseItem}>
        <Col xs={4} class={styles.selectedCaseItemName}>
          <div>{field}:</div>
        </Col>
        <Col xs={8} class={styles.selectedCaseItemValue}>
          {value}
        </Col>
      </Row>
    );

    return (
      <div>
        {items.length > 0 && (
          <TableToolBox selectable={false} class={styles.table}>
            <TableHead class={styles.tableHeadRow}>
              {this.showTalbeCol.map(key => {
                if (key === 'product_name' && tableType === 'direct' && !autoOrder) {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.tableHeadCell)}>
                      <Search
                        urlApi={urlApi}
                        suggest={suggest}
                        shopId={shopId}
                        openOnlyCases={openOnlyCases}
                        textFieldStyle={{
                          fontSize: '13px'
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={key} class={cls(styles.tableCell, styles.tableHeadCell)}>
                    {headers[key]}
                  </TableCell>
                );
              })}
            </TableHead>

            {items.map((item, index) => {
              const keyCode = index;
              return (
                <TableRow
                  key={keyCode}
                  class={cls(styles.tableRow, autoOrder ? 
                    {
                      [styles.tableRowStatus]: item.tree_status_id !== 0 && !item.corrected_count_valid,
                      [styles.correctedCountValid]: !item.corrected_count_valid,
                      [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                      [styles.finalCasesStyle]: item.is_final,
                    }
                    : {
                      [styles.tableRowStatus]: item.tree_status_id !== 0,
                      [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                      [styles.finalCasesStyle]: item.is_final,
                    })}
                  onClick={() => {
                    if (item.tree !== 'loading') {
                      this.handleItemOpen(item);
                    }
                  }}
                >
                  {this.showTalbeCol.map(field => (
                    <TableCell key={field} class={styles.tableCell}>
                      {field === 'status' && item.tree === 'loading' ? (
                        <CircularProgress class={classes.progressStatus} size={30} />
                      ) : (
                        item[field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableToolBox>
        )}

        {selectedItem && (
          <div className={styles.selectedCaseContainer}>
            <div className={styles.closeSelectedCase}>
              <CloseIcon onClick={this.handleItemClose} />
            </div>
            <div className={styles.selectedCaseWrap}>
              {Object.keys(headers).map(key => {
                if (!this.withoutColumns.includes(key)) {
                  return tableRow(key, headers[key], selectedItem[key]);
                }
              })}
              {tableType === 'direct' &&
                (infoLoading ? (
                  <Row between="xs" class={styles.infoPreload}>
                    <Col xs={12}>
                      <CircularProgress class={classes.progressSelectedItem} size={20} />
                    </Col>
                  </Row>
                ) : (
                  Object.keys(infoResponse).map(key => tableRow(key, key, infoResponse[key]))
                ))}
            </div>

            <div className={styles.controlWrap}>
              {manualCases && selectedItem.type_id === 4 ? (
                <ButtonDefault fullWidth onClick={() => this.deleteCase(selectedItem.item_id, selectedItem.type_id)}>
                  {lang.delete}
                </ButtonDefault>
              ) : (
                <TaskDropdown
                  data={typeof selectedItem['tree'] === 'object' ? selectedItem['tree'] : []}
                  shopId={shopId}
                  caseType={selectedItem.type_id}
                  itemId={selectedItem[itemIdField]}
                  loadToServer={this.loadActionToServer}
                  urlApi={urlApi}
                />
              )}
            </div>

            {tableType === 'direct' && (
              <div className={styles.controlWrap}>
                <Comment
                  urlApi={urlApi}
                  shopId={shopId}
                  itemId={selectedItem.item_id}
                  text={selectedItem.comment ? selectedItem.comment : ''}
                  caseType={selectedItem.type_id}
                />
              </div>
            )}

            {tableType === 'direct' && selectedItem['image'] && (
              <div className={styles.controlWrap}>
                <CarouselPhoto mobile mobileBtnText={lang.photo} images={selectedItem['image']} />
              </div>
            )}

            <div className={styles.controlWrap}>
              <ButtonDefault fullWidth onClick={this.handleItemClose}>
                {lang.back}
              </ButtonDefault>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MobileTable.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  suggest: PropTypes.string,
  classes: PropTypes.object,
  shopId: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  openOnlyCases: PropTypes.bool.isRequired,
  tableType: PropTypes.string.isRequired,
  language: PropTypes.object.isRequired,
  manualCases: PropTypes.bool.isRequired
};

export default connect(store => store)(withStyles(style)(MobileTable));
