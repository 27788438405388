export default {
  mobileFirefoxAlert: 'Neste navegador, o processamento de tarefas não está disponível.',
  datePicker: {
    from: 'De',
    to: 'Para',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    month: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    week: 'semana',
    weekShot: 'sem',
    buttonName: {
      date: 'Data',
      week: 'Semana',
      month: 'Mês'
    },
    modal: {
      header: 'Aviso',
      text: 'Intervalo de datas selecionado inválido',
      action: 'Aceitar'
    }
  },
  filters: {
    name: 'Nome'
  },
  header: {
    logout: 'Sair',
    changePassword: 'Alterar senha',
    settings: 'Configurações',
    title: 'Ver interface do usuário',
    searchTitle: 'Pesquisar usuário',
    input: 'Comece a digitar o nome ou login...',
    cancel: 'Cancelar',
    view: 'Visualizar',
    id: 'ID',
    login: 'Login',
    name: 'Nome',
    phone: 'Telefone',
    email: 'E-mail',
    stopSettings: 'Parar de visualizar'
  },
  scenario: {
    noDataReceived: 'Nenhum dado recebido',
    filterPart: {
      name: 'Nome',
      clear: 'Limpar',
      notFound: 'Não encontrado',
      search: 'Pesquisar...'
    }
  },
  directTask: {
    addCase: 'Adicionar caso',
    addComment: 'Comentário',
    actions: 'Ações',
    searchProduct: 'PLU/Código de barras',
    searchProductShort: 'PLU/Código de barras',
    photo: 'Foto',
    back: 'Voltar',
    treeEmpty: 'ACÇÕES INDISPONÍVEIS',
    step: 'Passo',
    sortingButton: 'Ordenar',
    sortButton: 'Ordenar',
    add: 'Adicionar',
    delete: 'Excluir',
    deleteManualCases: 'Eliminar tarefa',
    show: 'Mostrar',
    hide: 'Ocultar',
    comment: 'Comentário',
    showDoneCases: 'Mostrar tarefas concluídas',
    hideDoneCases: 'Ocultar tarefas concluídas'
  },
  gridTable: {
    pluList: 'Lista de PLU'
  },
  casesForm: {
    product: 'Produto',
    loadFoto: 'Carregar Foto',
    plu: 'Nome/PLU',
    success: 'Caso criado',
    add: 'Criar',
    new: 'Novo',
    delete: 'Excluir',
    cancel: 'Cancelar',
    comment: 'Comentário'
  },
  pagination: {
    apply: 'Ir',
    placeholder: 'Página'
  },
  restorePass: {
    noLength: 'A senha deve ter pelo menos 12 caracteres',
    noLowercase: 'A senha deve ter pelo menos uma letra minúscula',
    noUppercase: 'A senha deve ter pelo menos uma letra maiúscula',
    noNumber: 'A senha deve ter pelo menos um símbolo numérico',
    noMatch: 'As senhas não coincidem',
    password: 'Senha',
    repeatPassword: 'Repetir senha',
    ok: 'Aceitar',
    cancel: 'Cancelar'
  },
  navigation: {
    missions: 'Missões',
    scenarios: 'Cenários'
  },
  changePassword: {
    previousPassword: 'Senha atual',
    newPassword: 'Nova senha',
    confirmPassword: 'Confirme a nova senha',
    apply: 'Aplicar',
    title: 'Alterar senha',
    success: 'Senha alterada com sucesso',
    wrongPassword: 'Senha incorreta',
    weakPassword: 'A senha não atende aos requisitos',
    passwordsNotMatch: 'As senhas não coincidem',
    requirements: 'A nova senha deve ter pelo menos 12 caracteres, uma letra maiúscula e minúscula e um número' +
      'A nova senha deve ter pelo menos 12 caracteres, uma letra maiúscula e minúscula e um número'
  },
  auth: {
    login: 'Login',
    password: 'Senha',
    restorePass: 'Restaurar senha',
    submit: 'Enviar',
    loginError: 'Login e/ou senha inválidos'
  },
  restorePassForm: {
    restore: 'Restaurar',
    cancel: 'Cancelar',
    info: 'Por favor, insira seu e-mail registrado na plataforma GROSA',
    emailInvalid: 'E-mail fornecido não encontrado',
    email: 'E-mail',
    success: 'O link de restauração foi enviado para o seu e-mail'
  },
  search: {
    notFound: 'Não encontrado'
  },
  noMatchPage: {
    title: 'Página solicitada não encontrada',
    body: 'Voltar para a página inicial'
  },
  warnings: {
    title: 'Aviso',
    isDesktop: 'O upload de fotos está disponível apenas na versão móvel do sistema. Use a câmera do seu telefone ou tablet, por favor.'
  },
  errors: {
    netErrorText: 'Erro de rede. Por favor, recarregue a página',
    netErrorReload: 'Recarregar',
    netErrorClose: 'Fechar',
    netErrorTitle: 'Erro',
    noShops: 'OH! Parece que nenhuma loja foi atribuída a você ainda. Conte ao seu gerente sobre isso',
    noCases: 'arece que o nosso serviço não conseguiu encontrar uma única caixa nesta loja. Comece a criá-los você mesmo',
    noAutoOrder: 'Não há tarefas para ajustar a ordem automática',
    causeError: 'Erro',
    scenariosEmpty: 'O array de cenários está vazio'
  },
  officeUI: {
    resetZoom: 'Redefinir zoom'
  },
  gridAutocomplete: {
    placeholder: 'Comece a digitar o nome da loja',
    notFound: 'Loja não encontrada'
  },
  targets: {
    nothing: '',
    shop: 'Loja',
    category: 'Categoria',
    supplier: 'Fornecedor',
    vendor: 'Fornecedor',
    brand: 'Marca',
    plu: 'PLU',
    day: 'Dia',
    week: 'Semana',
    month: 'Mês',
    quarter: 'Trimestre',
    year: 'Ano'
  },
  bubbleChart: {
    qty: 'qty',
    currency: 'BRL'
  },
  chart: {
    quarter: 'trimestre',
    week: 'semana'
  },
  download: {
    CSV: 'Baixar CSV',
    XLS: 'Baixar XLS'
  },
  counterTask: {
    task: 'Tarefas pendentes',
    noTask: 'Nenhuma tarefa'
  },
  filtersShop: {
    shopName: 'Endereço da loja',
    shopId: 'Nome da loja'
  },
  chartCaption: {
    thousand: 'mil',
    million: 'milhão',
    billion: 'bilhão'
  },
  common: {
    close: 'Fechar',
    userNotFound: 'Usuário não encontrado',
    yes: 'Sim',
    no: 'Não',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "PLU interno",
      shop_id: "ID da loja",
      ass_type: "Tipo de sortimento",
      category_id3: "ID da seção",
      category_id4: "ID da categoria",
      stock: "Volume de saldos",
      stock_sum: "Quantidade de saldos",
      product_turnover: "Rotação",
      days_without_sales: "Dias sem vendas",
      last_sales_date: "Data da última venda",
      last_sales_volume: "Último volume de vendas",
      last_shipment_date: "Última data de entrega",
      last_shipment_volume: "Último volume de entrega",
      promo_start: "Início da promoção",
      promo_end: "Fim da promoção",
      promo_period: "Período da promoção",
      is_promo: "Promoção",
      title: "Nome",
      category_name3: "Seção",
      category_name4: "Categoria"
    },
    hideReadyTasks: "Ocultar tarefas concluídas",
    nothingFound: "Nada encontrado",
    shopsNotFound: "Lojas não encontradas",
    chooseShop: "Escolha uma loja",
    createTasks: "Criar tarefas",
    taskCreating: "Criando tarefas",
    next: "Continuar",
    columns: "Colunas",
    from: "De",
    ok: "OK",
    cancel: "Cancelar",
    filter: "Filtrar",
    chooseValue: "Escolher um valor",
    enterValue: "Digite um valor",
    rangeFrom: "De",
    rangeTo: "Para",
    selected: "Selecionado",
    foundRows: "Registros encontrados",
    createdTasksTotal: "Total de tarefas criadas"
  }
};
