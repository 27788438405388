export default {
  mobileFirefoxAlert: 'ამ ბრაუზერში დარეგისტრირება არ ხერხდება.',
  datePicker: {
    from: 'დან',
    to: 'მდე',
    cancel: 'გაუქმება',
    apply: 'მიღება',
    month: [
      'იანვარი',
      'თებერვალი',
      'მარტი',
      'აპრილი',
      'მაისი',
      'ივნისი',
      'ივლისი',
      'აგვისტო',
      'სექტემბერი',
      'ოქტომბერი',
      'ნოემბერი',
      'დეკემბერი'
    ],
    week: 'კვირა',
    weekShot: 'კვ.',
    buttonName: {
      date: 'თარიღი',
      week: 'კვირა',
      month: 'თვე'
    },
    modal: {
      header: 'გაფრენის შეტყობინება',
      text: 'არასწორი თარიღის შერჩევა',
      action: 'დადასტურება'
    }
  },
  filters: {
    name: 'სახელი'
  },
  scenario: {
    noDataReceived: 'მონაცემები არ მოიღება',
    filterPart: {
      name: 'სახელი',
      clear: 'გასუფთავება',
      notFound: 'არ არის',
      search: 'ძებნა...'
    }
  },
  header: {
    logout: 'გამოსვლა',
    changePassword: 'პაროლის შეცვლა',
    settings: 'ვერიფიკაცია',
    title: 'ხელხმისაწვდომი ინტერფეისი',
    searchTitle: 'მომხმარებლის ძებნა',
    input: 'დაწყება დასახელების ან ლოგინის შეტყობინება...',
    cancel: 'გაუქმება',
    view: 'ხედვა',
    id: 'ID',
    login: 'ლოგინი',
    name: 'სახელი',
    phone: 'ტელეფონი',
    email: 'ელ. ფოსტა',
    stopSettings: 'დაასტურე ნახვა'
  },
  directTask: {
    addCase: 'დამატება',
    commentCase: 'კომენტარი',
    actions: 'ქმედებები',
    searchProduct: 'PLU/შტრიხკოდი',
    searchProductShort: 'PLU/შტრიხკოდი',
    photo: 'ფოტო',
    back: 'უკან',
    treeEmpty: 'ქმედებები მიუწვდომელია',
    step: 'ნაბიჯი',
    sortingButton: 'დალაგება',
    sortButton: 'დალაგება',
    add: 'დამატება',
    delete: 'წაშლა',
    deleteManualCases: 'ხელითა კეისების წაშლა',
    show: 'ჩვენება',
    hide: 'დამალვა',
    comment: 'კომენტარი',
    showDoneCases: 'შესრულებული დავალებების ჩვენება',
    hideDoneCases: 'დასრულებული დავალებების დამალვა'
  },
  gridTable: {
    pluList: 'PLU სია'
  },
  casesForm: {
    product: 'პროდუქტი',
    loadFoto: 'ფოტოს ჩატვირთვა',
    plu: 'სახელი/PLU',
    success: 'კეისი შეიქმნა',
    delete: 'წაშლა',
    add: 'Შექმნა',
    new: 'ახალი',
    cancel: 'გაუქმება',
    comment: 'კომენტარი'
  },
  pagination: {
    apply: 'გადასვლა',
    placeholder: 'გვ. ნომერი'
  },
  restorePass: {
    noLength: 'პაროლი უნდა იყოს მინიმუმ 12 სიმბოლო',
    noLowercase: 'პაროლში უნდა იყოს ერთი პატვის სიმბოლო',
    noUppercase: 'პაროლში უნდა იყოს ერთი დიდი ასოის სიმბოლო',
    noNumber: 'პაროლში უნდა იყოს ერთი ციფრიანი სიმბოლო',
    noMatch: 'პაროლები არ ემთხვევა',
    password: 'პაროლი',
    repeatPassword: 'პაროლის რეპეტიცია',
    ok: 'დადასტურება',
    cancel: 'გაუქმება'
  },
  navigation: {
    missions: 'მისიები',
    scenarios: 'სცენარები'
  },
  changePassword: {
    previousPassword: 'მიმდინარე პაროლი',
    newPassword: 'ახალი პაროლი',
    confirmPassword: 'პაროლის რეპეტიცია',
    apply: 'მიღება',
    title: 'პაროლის შეცვლა',
    success: 'პაროლი შეიცვალა წარმატებით',
    wrongPassword: 'არასწორი პაროლი',
    weakPassword: 'პაროლი არ შეიცავს მოთხოვნებს',
    passwordsNotMatch: 'პაროლები არ ემთხვევა',
    requirements: 'ახალი პაროლი უნდა შეიცავდეს მინიმუმ 12 სიმბოლოს, 1 დიდ და პატვის სიმბოლოს და 1 ციფრის სიმბოლოს'
  },
  auth: {
    login: 'ლოგინი',
    password: 'პაროლი',
    restorePass: 'პაროლის აღდგენა',
    submit: 'წარმოადგენს',
    loginError: 'ლოგინი და/ან პაროლი არასწორია'
  },
  restorePassForm: {
    restore: 'აღდგენა',
    cancel: 'გაუქმება',
    info: 'შეიყვანეთ თქვენი ელ. ფოსტა, რომელიც რეგისტრირებულია GROSA-ზე',
    emailInvalid: 'წარმოადგენენი არ მოიძებნა',
    email: 'ელ. ფოსტა',
    success: 'აღდგენის ბმული გაიგზავნა თქვენი ელ. ფოსტაზე'
  },
  search: {
    notFound: 'ვერ მოიძებნა'
  },
  noMatchPage: {
    title: 'საჭირო გვერდი ვერ მოიძებნა',
    body: 'დაბრუნდით საწყის გვერდზე'
  },
  warnings: {
    title: 'გაფრენის შეტყობინება',
    isDesktop: 'სურსით ჩამოტვირთვა ხელისუფლება ხელსაწყო ვერსიის მობილურ ვერსიაში შესაძლებელია. გთხოვთ, გამოიყენოთ თქვენი ტელეფონის ან პლანშეტის კამერა.'
  },
  errors: {
    netErrorText: 'ქსელის შეფერხება. გთხოვთ, გადაიტვირთოთ გვერდი',
    netErrorReload: 'გადატვირთვა',
    netErrorTitle: 'შეცდომა',
    netErrorClose: 'დახურვა',
    noShops: 'ოჰ! როგორც ჩანს, ჯერ არცერთი მაღაზია არ დაგინიშნეს. უთხარით თქვენს მენეჯერს ამის შესახებ',
    noCases: 'როგორც ჩანს, ჩვენმა სერვისმა ვერ იპოვა რაიმე დავალება ამ მაღაზიაში. თავად დაიწყეთ მათი შექმნა',
    noAutoOrder: 'არ არის ამოცანები ავტომატური შეკვეთის რეგულირებისთვის',
    causeError: 'შეცდომა',
    scenariosEmpty: 'სცენარების მასივი ცარიელია'
  },
  officeUI: {
    resetZoom: 'ჩართვის განულება'
  },
  gridAutocomplete: {
    placeholder: 'დაწყება მაღაზის სახელის შეყვანა',
    notFound: 'მაღაზია ვერ მოიძებნა'
  },
  targets: {
    nothing: '',
    shop: 'მაღაზია',
    category: 'კატეგორია',
    supplier: 'მომწოდებელი',
    vendor: 'კომპანია',
    brand: 'ბრენდი',
    plu: 'PLU',
    day: 'დღე',
    week: 'კვირა',
    month: 'თვე',
    quarter: 'კვარტალი',
    year: 'წელი'
  },
  bubbleChart: {
    qty: 'რაოდენობა',
    currency: 'ლარი'
  },
  chart: {
    quarter: 'კვარტალი',
    week: 'კვირა'
  },
  download: {
    CSV: 'ჩამოტვირთე CSV',
    XLS: 'ჩამოტვირთე XLS'
  },
  counterTask: {
    task: 'მომლოდინებული დავალებები',
    noTask: 'დავალებები არ არის'
  },
  filtersShop: {
    shopName: 'მაღაზიის მისამართი',
    shopId: 'მაღაზიის სახელი'
  },
  chartCaption: {
    thousand: 'ათ',
    million: 'მ',
    billion: 'ბ'
  },
  common: {
    close: 'დახურვა',
    userNotFound: 'მომხმარებელი ვერ მოიძებნა',
    yes: 'დიახ',
    no: 'არა',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "შიდა PLU",
      shop_id: "მაღაზიის ID",
      ass_type: "ასორტიმენტის ტიპი",
      category_id3: "სექციის ID",
      category_id4: "კატეგორიის ID",
      stock: "ნარკის რვების მოცულობა",
      stock_sum: "ნარკების ჯამი",
      product_turnover: "ტირნვა",
      days_without_sales: "წუთები გარემოქმედების გარდა",
      last_sales_date: "ბოლო გაყიდვის თარიღი",
      last_sales_volume: "ბოლო გაყიდვის მოცულობა",
      last_shipment_date: "მიწოდების ბოლო თარიღი",
      last_shipment_volume: "მიწოდების ბოლო მოცულობა",
      promo_start: "პრომოციის დაწყება",
      promo_end: "პრომოციის დასრულება",
      promo_period: "პრომოციის პერიოდი",
      is_promo: "პრომო",
      title: "სახელი",
      category_name3: "სექცია",
      category_name4: "კატეგორია"
    },
    hideReadyTasks: "შეამოწმეთ მზარდად მზარდად მზარდად დაფა",
    nothingFound: "არაფერი არ მოიძებნა",
    shopsNotFound: "მაღაზიები არ მოიძებნა",
    chooseShop: "აირჩიეთ მაღაზია",
    createTasks: "დავალებების შექმნა",
    taskCreating: "დავალებების შექმნა",
    next: "გაგრძელება",
    columns: "სვეტები",
    from: "დან",
    ok: "კარგი",
    cancel: "გაუქმება",
    filter: "ფილტრი",
    chooseValue: "აირჩიეთ ღილაკი",
    enterValue: "შეიყვანეთ მნიშვნელობა",
    rangeFrom: "დან",
    rangeTo: "კონკრეტი",
    selected: "არჩეული",
    foundRows: "მოიძებნა რიგები",
    createdTasksTotal: "შექმნილი დავალებების სულ"
  }
};
