export default {
  mobileFirefoxAlert: 'I denne nettleseren er oppgavebehandling ikke tilgjengelig.',
  datePicker: {
    from: 'Fra',
    to: 'Til',
    cancel: 'Avbryt',
    apply: 'Bruk',
    month: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember'
    ],
    week: 'uke',
    weekShot: 'u',
    buttonName: {
      date: 'Dato',
      week: 'Uke',
      month: 'Måned'
    },
    modal: {
      header: 'Advarsel',
      text: 'Ugyldig datoperiode valgt',
      action: 'Godta'
    }
  },
  filters: {
    name: 'Navn'
  },
  scenario: {
    noDataReceived: 'Ingen data mottatt',
    filterPart: {
      name: 'Navn',
      clear: 'Tøm',
      notFound: 'Ikke funnet',
      search: 'Søk...'
    }
  },
  header: {
    logout: 'Logg ut',
    changePassword: 'Endre passord',
    settings: 'Innstillinger',
    title: 'Vis brukergrensesnitt',
    searchTitle: 'Søk etter bruker',
    input: 'Begynn å skrive navn eller brukernavn...',
    cancel: 'Avbryt',
    view: 'Vis',
    id: 'ID',
    login: 'Brukernavn',
    name: 'Navn',
    phone: 'Telefon',
    email: 'E-post',
    stopSettings: 'Stopp visning'
  },
  directTask: {
    addCase: 'Legg til sak',
    commentCase: 'kommentarSak',
    actions: 'Handlinger',
    searchProduct: 'PLU/Strekkode',
    searchProductShort: 'PLU/Strekkode',
    photo: 'Bilde',
    back: 'Tilbake',
    treeEmpty: 'HANDLINGER ER IKKE TILGJENGELIGE',
    step: 'Steg',
    sortingButton: 'Sortering',
    sortButton: 'Sorter',
    add: 'Legg til',
    delete: 'Slett',
    deleteManualCases: 'Slett manuelle saker',
    show: 'Vis',
    hide: 'Skjul',
    comment: 'Kommentar',
    showDoneCases: 'Vis fullførte oppgaver',
    hideDoneCases: 'Skjul fullførte oppgaver'
  },
  gridTable: {
    pluList: 'PLU-liste'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Last opp bilde',
    plu: 'Navn/PLU',
    success: 'Sak opprettet',
    delete: 'Slett',
    add: 'Skape',
    new: 'Ny',
    cancel: 'Avbryt',
    comment: 'Kommentar'
  },
  pagination: {
    apply: 'Gå',
    placeholder: 'Side'
  },
  restorePass: {
    noLength: 'Passord må ha minst 12 tegn',
    noLowercase: 'Passordet må inneholde minst en liten bokstav',
    noUppercase: 'Passordet må inneholde minst en stor bokstav',
    noNumber: 'Passordet må inneholde minst ett tall',
    noMatch: 'Passordene stemmer ikke',
    password: 'Passord',
    repeatPassword: 'Gjenta passord',
    ok: 'Godta',
    cancel: 'Avbryt'
  },
  navigation: {
    missions: 'Oppdrag',
    scenarios: 'Scenarier'
  },
  changePassword: {
    previousPassword: 'Nåværende passord',
    newPassword: 'Nytt passord',
    confirmPassword: 'Bekreft nytt passord',
    apply: 'Bruk',
    title: 'Endre passord',
    success: 'Passordet ble endret',
    wrongPassword: 'Feil passord',
    weakPassword: 'Passordet oppfyller ikke kravene',
    passwordsNotMatch: 'Passordene stemmer ikke',
    requirements: 
      'Det nye passordet må ha minst 12 tegn, én stor og én liten bokstav og ett tall'
  },
  auth: {
    login: 'Logg inn',
    password: 'Passord',
    restorePass: 'Gjenopprett passord',
    submit: 'Send',
    loginError: 'Brukernavn og/eller passord er ugyldige'
  },
  restorePassForm: {
    restore: 'Gjenopprett',
    cancel: 'Avbryt',
    info: 'Vennligst oppgi e-postadressen din som er registrert i GROSA',
    emailInvalid: 'Den innsendte e-postadressen ble ikke funnet',
    email: 'E-post',
    success: 'Lenke for gjenoppretting er sendt til e-posten din'
  },
  search: {
    notFound: 'Ikke funnet'
  },
  noMatchPage: {
    title: 'Ønsket side ikke funnet',
    body: 'Gå tilbake til hjemmesiden'
  },
  warnings: {
    title: 'Advarsel',
    isDesktop: 'Opplasting av bilder er bare tilgjengelig i mobilversjonen av systemet. Bruk kameraet på mobilen eller nettbrettet ditt.'
  },
  errors: {
    netErrorText: 'Nettverksfeil. Vennligst last inn siden på nytt',
    netErrorReload: 'Last inn på nytt',
    netErrorTitle: 'Feil',
    netErrorClose: 'Lukk',
    noShops: 'ÅH! Det ser ut til at ingen butikker har blitt tildelt deg ennå. Fortell lederen din om det',
    noCases: 'Det ser ut til at vår tjeneste ikke kunne finne en eneste sak i denne butikken. Begynn å lage dem selv',
    noAutoOrder: 'Det er ingen oppgaver for å justere den automatiske rekkefølgen',
    causeError: 'Feil',
    scenariosEmpty: 'Scenarieoppstillingen er tom'
  },
  officeUI: {
    resetZoom: 'Tilbakestill zoom'
  },
  gridAutocomplete: {
    placeholder: 'Begynn å skrive butikknavn',
    notFound: 'Butikken ble ikke funnet'
  },
  targets: {
    nothing: '',
    shop: 'Butikk',
    category: 'Kategori',
    supplier: 'Leverandør',
    vendor: 'Selger',
    brand: 'Merke',
    plu: 'PLU',
    day: 'Dag',
    week: 'Uke',
    month: 'Måned',
    quarter: 'Kvartal',
    year: 'År'
  },
  bubbleChart: {
    qty: 'antall',
    currency: 'NOK'
  },
  chart: {
    quarter: 'Kvartal',
    week: 'Uke'
  },
  download: {
    CSV: 'Last ned CSV',
    XLS: 'Last ned XLS'
  },
  counterTask: {
    task: 'Ventende oppgaver',
    noTask: 'Ingen oppgaver'
  },
  filtersShop: {
    shopName: 'Butikkadresse',
    shopId: 'Butikknavn'
  },
  chartCaption: {
    thousand: 'tusen',
    million: 'million',
    billion: 'milliard'
  },
  common: {
    close: 'Lukk',
    userNotFound: 'Bruker ikke funnet',
    yes: 'Ja',
    no: 'Nei'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Intern PLU',
      shop_id: 'Butikk-ID',
      ass_type: 'Sortimentstype',
      category_id3: 'Seksjons-ID',
      category_id4: 'Kategori-ID',
      stock: 'Lagerbeholdning',
      stock_sum: 'Sum av beholdninger',
      product_turnover: 'Omløpshastighet',
      days_without_sales: 'Dager uten salg',
      last_sales_date: 'Dato for siste salg',
      last_sales_volume: 'Siste salgsvolum',
      last_shipment_date: 'Siste forsendelsesdato',
      last_shipment_volume: 'Siste leveringsvolum',
      promo_start: 'Kampanjestart',
      promo_end: 'Kampanje slutt',
      promo_period: 'Kampanjeperiode',
      is_promo: 'Kampanje',
      title: 'Navn',
      category_name3: 'Seksjon',
      category_name4: 'Kategori'
    },
    hideReadyTasks: 'Skjul klare oppgaver',
    nothingFound: 'Ingenting funnet',
    shopsNotFound: 'Ingen butikker funnet',
    chooseShop: 'Velg en butikk',
    createTasks: 'Opprett oppgaver',
    taskCreating: 'Oppretter oppgaver',
    next: 'Fortsett',
    columns: 'Kolonner',
    from: 'Fra',
    ok: 'OK',
    cancel: 'Avbryt',
    filter: 'Filter',
    chooseValue: 'Velg en verdi',
    enterValue: 'Skriv inn verdi',
    rangeFrom: 'Fra',
    rangeTo: 'Til',
    selected: 'Valgt',
    foundRows: 'Antall funnete rader',
    createdTasksTotal: 'Totalt opprettede oppgaver'
  }
};
