export default {
  mobileFirefoxAlert: 'I denne browser er opgavebehandling ikke tilgængelig.',
  datePicker: {
    from: 'Fra',
    to: 'Til',
    cancel: 'Annuller',
    apply: 'Anvend',
    month: [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december'
    ],
    week: 'uge',
    weekShot: 'u',
    buttonName: {
      date: 'Dato',
      week: 'Uge',
      month: 'Måned'
    },
    modal: {
      header: 'Advarsel',
      text: 'Ugyldigt datointerval valgt',
      action: 'Accepter'
    }
  },
  filters: {
    name: 'Navn'
  },
  scenario: {
    noDataReceived: 'Ingen data modtaget',
    filterPart: {
      name: 'Navn',
      clear: 'Ryd',
      notFound: 'Ikke fundet',
      search: 'Søg...'
    }
  },
  header: {
    logout: 'Log ud',
    changePassword: 'Skift adgangskode',
    settings: 'Indstillinger',
    title: 'Se brugergrænseflade',
    searchTitle: 'Søg bruger',
    input: 'Begynd at skrive navn eller login...',
    cancel: 'Annuller',
    view: 'Vis',
    id: 'ID',
    login: 'Login',
    name: 'Navn',
    phone: 'Telefon',
    email: 'E-mail',
    stopSettings: 'Stop visning'
  },
  directTask: {
    addCase: 'Tilføj sag',
    commentCase: 'kommenterSag',
    actions: 'Handlinger',
    searchProduct: 'PLU/Stregkode',
    searchProductShort: 'PLU/Stregkode',
    photo: 'Foto',
    back: 'Tilbage',
    treeEmpty: 'HANDLINGER ER IKKE TILGÆNGELIGE',
    step: 'Trin',
    sortingButton: 'Sortering',
    sortButton: 'Sorter',
    add: 'Tilføj',
    delete: 'Slet',
    deleteManualCases: 'Slet manuelle sager',
    show: 'Vis',
    hide: 'Skjul',
    comment: 'Kommentar',
    showDoneCases: 'Vis udførte opgaver',
    hideDoneCases: 'Skjul udførte opgaver'
  },
  gridTable: {
    pluList: 'PLU-liste'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Indlæs billede',
    plu: 'Navn/PLU',
    success: 'Sag oprettet',
    delete: 'Slet',
    add: 'Skab',
    new: 'Ny',
    cancel: 'Afbestille',
    comment: 'Kommentar'
  },
  pagination: {
    apply: 'Gå',
    placeholder: 'Side'
  },
  restorePass: {
    noLength: 'Adgangskoden skal være mindst 12 tegn lang',
    noLowercase: 'Adgangskoden skal indeholde mindst et lille bogstav',
    noUppercase: 'Adgangskoden skal indeholde mindst et stort bogstav',
    noNumber: 'Adgangskoden skal indeholde mindst ét tal',
    noMatch: 'Adgangskoderne stemmer ikke overens',
    password: 'Adgangskode',
    repeatPassword: 'Gentag adgangskode',
    ok: 'Accepter',
    cancel: 'Annuller'
  },
  navigation: {
    missions: 'Opgaver',
    scenarios: 'Scenarier'
  },
  changePassword: {
    previousPassword: 'Nuværende adgangskode',
    newPassword: 'Ny adgangskode',
    confirmPassword: 'Gentag ny adgangskode',
    apply: 'Anvend',
    title: 'Skift adgangskode',
    success: 'Adgangskoden blev ændret med succes',
    wrongPassword: 'Forkert adgangskode',
    weakPassword: 'Adgangskoden opfylder ikke kravene',
    passwordsNotMatch: 'Adgangskoderne matcher ikke',
    requirements: 'Den nye adgangskode skal være mindst 12 tegn lang og indeholde mindst ét stort og ét lille bogstav samt ét tal'
  },
  auth: {
    login: 'Login',
    password: 'Adgangskode',
    restorePass: 'Gendan adgangskode',
    submit: 'Indsend',
    loginError: 'Login og/eller adgangskode er ugyldig(e)'
  },
  restorePassForm: {
    restore: 'Gendan',
    cancel: 'Annuller',
    info: 'Angiv din e-mail, som er registreret i GROSA',
    emailInvalid: 'Den indsendte e-mail blev ikke fundet',
    email: 'E-mail',
    success: 'Gendannelseslinket blev sendt til din e-mail'
  },
  search: {
    notFound: 'Ikke fundet'
  },
  noMatchPage: {
    title: 'Forespurgt side blev ikke fundet',
    body: 'Gå tilbage til startsiden'
  },
  warnings: {
    title: 'Advarsel',
    isDesktop:
      'Upload af billeder er kun tilgængelig i systemets mobile version. Brug kameraet på din telefon eller tablet.'
  },
  errors: {
    netErrorText: 'Netværksfejl. Opdater venligst siden',
    netErrorReload: 'Genindlæs',
    netErrorTitle: 'Fejl',
    netErrorClose: 'Luk',
    noShops: 'Åh! Det ser ud til, at ingen butikker er blevet tildelt dig endnu. Fortæl det til din leder',
    noCases: 'Det ser ud til, at vores service ikke kunne finde en eneste sag i denne butik. Begynd at skabe dem selv',
    noAutoOrder: 'Der er ingen opgaver til at justere den automatiske rækkefølge',
    causeError: 'Fejl',
    scenariosEmpty: 'Scenariearrayet er tomt'
  },
  officeUI: {
    resetZoom: 'Nulstil zoom'
  },
  gridAutocomplete: {
    placeholder: 'Begynd at skrive butiksnavn',
    notFound: 'Butik ikke fundet'
  },
  targets: {
    nothing: '',
    shop: 'Butik',
    category: 'Kategori',
    supplier: 'Leverandør',
    vendor: 'Sælger',
    brand: 'Brand',
    plu: 'PLU',
    day: 'Dag',
    week: 'Uge',
    month: 'Måned',
    quarter: 'Kvartal',
    year: 'År'
  },
  bubbleChart: {
    qty: 'antal',
    currency: 'kr'
  },
  chart: {
    quarter: 'kvartal',
    week: 'uge'
  },
  download: {
    CSV: 'Hent CSV',
    XLS: 'Hent XLS'
  },
  counterTask: {
    task: 'Afventende opgaver',
    noTask: 'Ingen opgaver'
  },
  filtersShop: {
    shopName: 'Butikadresse',
    shopId: 'Butiksnavn'
  },
  chartCaption: {
    thousand: 'tusind',
    million: 'million',
    billion: 'milliard'
  },
  common: {
    close: 'Luk',
    userNotFound: 'Bruger ikke fundet',
    yes: 'Ja',
    no: 'Nej'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Intern PLU',
      shop_id: 'Butik ID',
      ass_type: 'Assortimenttype',
      category_id3: 'Sektion ID',
      category_id4: 'Kategori ID',
      stock: 'Lagerbeholdning',
      stock_sum: 'Saldoens beløb',
      product_turnover: 'Omsætning',
      days_without_sales: 'Dage uden salg',
      last_sales_date: 'Dato for sidste salg',
      last_sales_volume: 'Sidste salgsvolumen',
      last_shipment_date: 'Sidste leveringsdato',
      last_shipment_volume: 'Sidste leveringsvolumen',
      promo_start: 'Promo start',
      promo_end: 'Promo slut',
      promo_period: 'Promo periode',
      is_promo: 'Promo',
      title: 'Navn',
      category_name3: 'Sektion',
      category_name4: 'Kategori'
    },
    hideReadyTasks: 'Skjul færdige opgaver',
    nothingFound: 'Intet fundet',
    shopsNotFound: 'Ingen butikker fundet',
    chooseShop: 'Vælg en butik',
    createTasks: 'Opret opgaver',
    taskCreating: 'Opretter opgaver',
    next: 'Næste',
    columns: 'Kolonner',
    from: 'Fra',
    ok: 'OK',
    cancel: 'Annuller',
    filter: 'Filter',
    chooseValue: 'Vælg en værdi',
    enterValue: 'Indtast værdi',
    rangeFrom: 'Fra',
    rangeTo: 'Til',
    selected: 'Valgt',
    foundRows: 'Fundne rækker',
    createdTasksTotal: 'Oprettede opgaver i alt'
  }
};
