import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import MDSpinner from 'react-md-spinner';

import KpiElement from './KpiElement';
import { showKpiDebugAction } from '../../store/actions/actions';

import styles from './KpiDebug.css';

class KpiDebug extends Component {
  state = {};

  closeHandle = () => {
    const { dispatch } = this.props;
    dispatch(showKpiDebugAction(false));
  };

  renderContent = () => {
    const { data } = this.props.data;
    let i = 0;
    return data && data.length > 0 ? (
      data.sort((a, b) => b.timestamp - a.timestamp).map(item => {
        i++;
        item.i = i;
        return (
          <div className={styles.item} key={item.timestamp}>
            <KpiElement item={item} />
          </div>
        );
      })
    ) : (
      <div className={styles.preload}>
        <MDSpinner size={24} singleColor="#398bdf" />
      </div>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <div className={[styles.wrapper, !data.visible && styles.hidden].join(' ')}>
        <div className={styles.close} onClick={this.closeHandle} role="presentation">
          <CloseIcon />
        </div>
        <div className={styles.title}>Debug</div>
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.kpiDebug
  };
}

KpiDebug.defaultProps = {
  data: {}
};

KpiDebug.propTypes = {
  data: PropTypes.object, // eslint-disable-line
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(KpiDebug);
