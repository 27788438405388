export default {
  mobileFirefoxAlert: 'У цьому браузері обробка завдань недоступна.',
  datePicker: {
    from: 'З',
    to: 'До',
    cancel: 'Скасувати',
    apply: 'Застосувати',
    month: [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень'
    ],
    week: 'тиждень',
    weekShot: 'т',
    buttonName: {
      date: 'Дата',
      week: 'Тиждень',
      month: 'Місяць'
    },
    modal: {
      header: 'Попередження',
      text: 'Вибраний недійсний діапазон дат',
      action: 'Прийняти'
    }
  },
  filters: {
    name: 'Ім\'я'
  },
  scenario: {
    noDataReceived: 'Дані не отримано',
    filterPart: {
      name: 'Ім\'я',
      clear: 'Очистити',
      notFound: 'Не знайдено',
      search: 'Пошук...'
    }
  },
  header: {
    logout: 'Вийти',
    changePassword: 'Змінити пароль',
    settings: 'Налаштування',
    title: 'Перегляд інтерфейсу користувача',
    searchTitle: 'Пошук користувача',
    input: 'Почніть вводити ім\'я або логін...',
    cancel: 'Скасувати',
    view: 'Перегляд',
    id: 'ID',
    login: 'Логін',
    name: 'Ім\'я',
    phone: 'Телефон',
    email: 'Електронна пошта',
    stopSettings: 'Зупинити перегляд'
  },
  directTask: {
    addCase: 'Додати випадок',
    commentCase: 'Коментар до випадку',
    actions: 'Дії',
    searchProduct: 'PLU/Штрих-код',
    searchProductShort: 'PLU/Штрих-код',
    photo: 'Фото',
    back: 'Назад',
    treeEmpty: 'ДОСТУПНІ ДІЇ ВІДСУТНІ',
    step: 'Крок',
    sortingButton: 'Сортування',
    sortButton: 'Сортувати',
    add: 'Додати',
    delete: 'Видалити',
    deleteManualCases: 'Видалити вручні випадки',
    show: 'Показати',
    hide: 'Приховати',
    comment: 'Коментар',
    showDoneCases: 'Показати завершені завдання',
    hideDoneCases: 'Приховати завершені завдання'
  },
  gridTable: {
    pluList: 'Список PLU'
  },
  casesForm: {
    product: 'Продукт',
    loadFoto: 'Завантажити фото',
    plu: 'Назва/PLU',
    success: 'Випадок створено',
    delete: 'Видалити',
    add: 'Створити',
    new: 'Новий',
    cancel: 'Відміна',
    comment: 'Коментар'
  },
  pagination: {
    apply: 'Перейти',
    placeholder: 'Сторінка'
  },
  restorePass: {
    noLength: 'Пароль повинен містити принаймні 12 символів',
    noLowercase: 'Пароль повинен містити принаймні одну строчну літеру',
    noUppercase: 'Пароль повинен містити принаймні одну велику літеру',
    noNumber: 'Пароль повинен містити принаймні один числовий символ',
    noMatch: 'Паролі не збігаються',
    password: 'Пароль',
    repeatPassword: 'Повторіть пароль',
    ok: 'Прийняти',
    cancel: 'Скасувати'
  },
  navigation: {
    missions: 'Місії',
    scenarios: 'Сценарії'
  },
  changePassword: {
    previousPassword: 'Поточний пароль',
    newPassword: 'Новий пароль',
    confirmPassword: 'Підтвердіть новий пароль',
    apply: 'Застосувати',
    title: 'Зміна пароля',
    success: 'Пароль успішно змінено',
    wrongPassword: 'Невірний пароль',
    weakPassword: 'Пароль не відповідає вимогам',
    passwordsNotMatch: 'Паролі не збігаються',
    requirements: 'Новий пароль повинен містити принаймні 12 символів, 1 велику та 1 малу літеру та 1 цифру'
  },
  auth: {
    login: 'Логін',
    password: 'Пароль',
    restorePass: 'Відновлення паролю',
    submit: 'Надіслати',
    loginError: 'Неправильний логін та/або пароль'
  },
  restorePassForm: {
    restore: 'Відновити',
    cancel: 'Скасувати',
    info: 'Будь ласка, введіть свою електронну адресу, зареєстровану в GROSA',
    emailInvalid: 'Введений електронний лист не був знайдений',
    email: 'Електронна пошта',
    success: 'Посилання на відновлення було надіслано на вашу електронну пошту'
  },
  search: {
    notFound: 'Не знайдено'
  },
  noMatchPage: {
    title: 'Запитана сторінка не знайдена',
    body: 'Повернутися на головну сторінку'
  },
  warnings: {
    title: 'Попередження',
    isDesktop: 'Завантаження фотографій доступно лише в мобільній версії системи. Використовуйте камеру на вашому телефоні або планшеті, будь ласка.'
  },
  errors: {
    netErrorText: 'Помилка мережі. Будь ласка, перезавантажте сторінку',
    netErrorReload: 'Перезавантажити',
    netErrorTitle: 'Помилка',
    netErrorClose: 'Закрити',
    noShops: 'Ой! Схоже, вам ще не призначили жодного магазину. Скажіть про це своєму керівнику',
    noCases: 'Схоже, що наш сервіс не зміг знайти у цьому магазині жодного кейсу. Почніть створювати їх самостійно',
    noAutoOrder: 'Немає завдань на коригування автозамовлення',
    causeError: 'Помилка',
    scenariosEmpty: 'Масив сценаріїв порожній'
  },
  officeUI: {
    resetZoom: 'Скинути масштаб'
  },
  gridAutocomplete: {
    placeholder: 'Почніть вводити назву магазину',
    notFound: 'Магазин не знайдено'
  },
  targets: {
    nothing: '',
    shop: 'Магазин',
    category: 'Категорія',
    supplier: 'Постачальник',
    vendor: 'Постачальник',
    brand: 'Бренд',
    plu: 'PLU',
    day: 'День',
    week: 'Тиждень',
    month: 'Місяць',
    quarter: 'Квартал',
    year: 'Рік'
  },
  bubbleChart: {
    qty: 'кількість',
    currency: 'грн'
  },
  chart: {
    quarter: 'квартал',
    week: 'тиждень'
  },
  download: {
    CSV: 'Завантажити CSV',
    XLS: 'Завантажити XLS'
  },
  counterTask: {
    task: 'Невиконані завдання',
    noTask: 'Немає завдань'
  },
  filtersShop: {
    shopName: 'Адреса магазину',
    shopId: 'Назва магазину'
  },
  chartCaption: {
    thousand: 'тис.',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Закрити',
    userNotFound: 'Користувача не знайдено',
    yes: 'Так',
    no: 'Ні'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Внутрішній PLU',
      shop_id: 'ID магазину',
      ass_type: 'Тип асортименту',
      category_id3: 'ID секції',
      category_id4: 'ID категорії',
      stock: 'Обсяг залишків',
      stock_sum: 'Сума балансів',
      product_turnover: 'Оборот',
      days_without_sales: 'Дні без продажу',
      last_sales_date: 'Дата останнього продажу',
      last_sales_volume: 'Обсяг останньої продажу',
      last_shipment_date: 'Дата останнього постачання',
      last_shipment_volume: 'Обсяг останнього постачання',
      promo_start: 'Початок промо',
      promo_end: 'Закінчення промо',
      promo_period: 'Тривалість промо',
      is_promo: 'Промо',
      title: 'Назва',
      category_name3: 'Секція',
      category_name4: 'Категорія'
    },
    hideReadyTasks: 'Приховати готові завдання',
    nothingFound: 'Нічого не знайдено',
    shopsNotFound: 'Магазини не знайдені',
    chooseShop: 'Оберіть магазин',
    createTasks: 'Створити завдання',
    taskCreating: 'Створення завдань',
    next: 'Продовжити',
    columns: 'Стовпці',
    from: 'Від',
    ok: 'OK',
    cancel: 'Скасувати',
    filter: 'Фільтр',
    chooseValue: 'Оберіть значення',
    enterValue: 'Введіть значення',
    rangeFrom: 'Від',
    rangeTo: 'До',
    selected: 'Вибрано',
    foundRows: 'Знайдено рядків',
    createdTasksTotal: 'Всього створено завдань'
  }
};
