export default {
  mobileFirefoxAlert: 'V této prohlížeči není dostupné zpracování úkolů.',
  datePicker: {
    from: 'Od',
    to: 'Do',
    cancel: 'Zrušit',
    apply: 'Použít',
    month: [
      'leden',
      'únor',
      'březen',
      'duben',
      'květen',
      'červen',
      'červenec',
      'srpen',
      'září',
      'říjen',
      'listopad',
      'prosinec'
    ],
    week: 'týden',
    weekShot: 't',
    buttonName: {
      date: 'Datum',
      week: 'Týden',
      month: 'Měsíc'
    },
    modal: {
      header: 'Varování',
      text: 'Byl vybrán neplatný rozsah datumů',
      action: 'Přijmout'
    }
  },
  filters: {
    name: 'Jméno'
  },
  scenario: {
    noDataReceived: 'Nebyly obdrženy žádné údaje',
    filterPart: {
      name: 'Jméno',
      clear: 'Vymazat',
      notFound: 'Nenalezeno',
      search: 'Hledat...'
    }
  },
  header: {
    logout: 'Odhlásit se',
    changePassword: 'Změnit heslo',
    settings: 'Nastavení',
    title: 'Zobrazit uživatelské rozhraní',
    searchTitle: 'Hledat uživatele',
    input: 'Začněte psát jméno nebo přihlašovací jméno...',
    cancel: 'Zrušit',
    view: 'Zobrazit',
    id: 'ID',
    login: 'Přihlášení',
    name: 'Jméno',
    phone: 'Telefon',
    email: 'E-mail',
    stopSettings: 'Zastavit prohlížení'
  },
  directTask: {
    addCase: 'Přidat případ',
    commentCase: 'komentářPřípadu',
    actions: 'Akce',
    searchProduct: 'PLU/Čárový kód',
    searchProductShort: 'PLU/Čárový kód',
    photo: 'Fotografie',
    back: 'Zpět',
    treeEmpty: 'AKCE NEJSOU DOSTUPNÉ',
    step: 'Krok',
    sortingButton: 'Řazení',
    sortButton: 'Seřadit',
    add: 'Přidat',
    delete: 'Smazat',
    deleteManualCases: 'Smazat ruční případy',
    show: 'Zobrazit',
    hide: 'Skrýt',
    comment: 'Komentář',
    showDoneCases: 'Zobrazit dokončené úkoly',
    hideDoneCases: 'Skrýt dokončené úkoly'
  },
  gridTable: {
    pluList: 'Seznam PLU'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Nahrát fotku',
    plu: 'Název/PLU',
    success: 'Případ vytvořen',
    delete: 'Vymazat',
    add: 'Vytvořit',
    new: 'Nový',
    cancel: 'Zrušení',
    comment: 'Komentář'
  },
  pagination: {
    apply: 'Přejít',
    placeholder: 'Stránka'
  },
  restorePass: {
    noLength: 'Heslo musí mít minimálně 12 znaků',
    noLowercase: 'Heslo musí obsahovat minimálně jedno malé písmeno',
    noUppercase: 'Heslo musí obsahovat minimálně jedno velké písmeno',
    noNumber: 'Heslo musí obsahovat minimálně jedno číslo',
    noMatch: 'Hesla nesouzní',
    password: 'Heslo',
    repeatPassword: 'Opakovat heslo',
    ok: 'OK',
    cancel: 'Zrušit'
  },
  navigation: {
    missions: 'Úkoly',
    scenarios: 'Scénáře'
  },
  changePassword: {
    previousPassword: 'Stávající heslo',
    newPassword: 'Nové heslo',
    confirmPassword: 'Potvrzení nového hesla',
    apply: 'Použít',
    title: 'Změna hesla',
    success: 'Heslo bylo úspěšně změněno',
    wrongPassword: 'Nesprávné heslo',
    weakPassword: 'Heslo nevyhovuje požadavkům',
    passwordsNotMatch: 'Hesla nesouzní',
    requirements: 'Nové heslo by mělo mít minimálně 12 znaků, 1 velké a 1 malé písmeno a 1 číslici'
  },
  auth: {
    login: 'Přihlášení',
    password: 'Heslo',
    restorePass: 'Obnovit heslo',
    submit: 'Odeslat',
    loginError: 'Neplatné přihlášení a/nebo heslo'
  },
  restorePassForm: {
    restore: 'Obnovit',
    cancel: 'Zrušit',
    info: 'Zadejte svůj e-mail, který je zaregistrován v platformě GROSA',
    emailInvalid: 'Zadaný e-mail nebyl nalezen',
    email: 'E-mail',
    success: 'Odkaz k obnovení byl odeslán na váš e-mail'
  },
  search: {
    notFound: 'Nenalezeno'
  },
  noMatchPage: {
    title: 'Požadovaná stránka nebyla nalezena',
    body: 'Zpět na úvodní stránku'
  },
  warnings: {
    title: 'Varování',
    isDesktop: 'Nahrávání fotografií je možné pouze v mobilní verzi systému. Použijte fotoaparát ve svém telefonu nebo tabletu.'
  },
  errors: {
    netErrorText: 'Chyba sítě. Prosím, znovu načtěte stránku',
    netErrorReload: 'Znovu načíst',
    netErrorTitle: 'Chyba',
    netErrorClose: 'Zavřít',
    noShops: 'Jejda! Zdá se, že vám zatím nebyly přiřazeny žádné obchody. Řekněte o tom svému manažerovi',
    noCases: 'Zdá se, že naše služba v tomto obchodě nenašla jediný případ. Začněte je vytvářet sam',
    noAutoOrder: 'Neexistují žádné úkoly pro úpravu automatického pořadí',
    causeError: 'Chyba',
    scenariosEmpty: 'Pole scénářů je prázdné'
  },
  officeUI: {
    resetZoom: 'Resetovat přiblížení'
  },
  gridAutocomplete: {
    placeholder: 'Začněte psát název obchodu',
    notFound: 'Obchod nenalezen'
  },
  targets: {
    nothing: '',
    shop: 'Obchod',
    category: 'Kategorie',
    supplier: 'Dodavatel',
    vendor: 'Dodavatel',
    brand: 'Značka',
    plu: 'PLU',
    day: 'Den',
    week: 'Týden',
    month: 'Měsíc',
    quarter: 'Čtvrtletí',
    year: 'Rok'
  },
  bubbleChart: {
    qty: 'množství',
    currency: 'Kč'
  },
  chart: {
    quarter: 'čtvrtletí',
    week: 'týden'
  },
  download: {
    CSV: 'Stáhnout CSV',
    XLS: 'Stáhnout XLS'
  },
  counterTask: {
    task: 'Nevyřízené úkoly',
    noTask: 'Žádné úkoly'
  },
  filtersShop: {
    shopName: 'Adresa obchodu',
    shopId: 'Název obchodu'
  },
  chartCaption: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mld.'
  },
  common: {
    close: 'Zavřít',
    userNotFound: 'Uživatel nenalezen',
    yes: 'Ano',
    no: 'Ne'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Interní PLU',
      shop_id: 'ID obchodu',
      ass_type: 'Typ sortimentu',
      category_id3: 'ID sekce',
      category_id4: 'ID kategorie',
      stock: 'Objem zbytků',
      stock_sum: 'Množství zbytků',
      product_turnover: 'Obrat',
      days_without_sales: 'Dny bez prodeje',
      last_sales_date: 'Datum posledního prodeje',
      last_sales_volume: 'Objem posledního prodeje',
      last_shipment_date: 'Datum poslední dodávky',
      last_shipment_volume: 'Objem poslední dodávky',
      promo_start: 'Začátek promoce',
      promo_end: 'Konec promoce',
      promo_period: 'Doba trvání promoce',
      is_promo: 'Promoce',
      title: 'Název',
      category_name3: 'Sekce',
      category_name4: 'Kategorie'
    },
    hideReadyTasks: 'Skrýt hotové úkoly',
    nothingFound: 'Nenalezeno nic',
    shopsNotFound: 'Obchody nebyly nalezeny',
    chooseShop: 'Vyberte obchod',
    createTasks: 'Vytvořit úkoly',
    taskCreating: 'Vytváření úkolů',
    next: 'Pokračovat',
    columns: 'Sloupce',
    from: 'Od',
    ok: 'OK',
    cancel: 'Zrušit',
    filter: 'Filtrovat',
    chooseValue: 'Vyberte hodnotu',
    enterValue: 'Zadejte hodnotu',
    rangeFrom: 'Od',
    rangeTo: 'Do',
    selected: 'Vybráno',
    foundRows: 'Nalezené řádky',
    createdTasksTotal: 'Celkově vytvořeno úkolů'
  }
};
