export default {
  mobileFirefoxAlert: 'W tej przeglądarce przetwarzanie zadań jest niedostępne.',
  datePicker: {
    from: 'Od',
    to: 'Do',
    cancel: 'Anuluj',
    apply: 'Zastosuj',
    month: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień'
    ],
    week: 'tydzień',
    weekShot: 'tydz',
    buttonName: {
      date: 'Data',
      week: 'Tydzień',
      month: 'Miesiąc'
    },
    modal: {
      header: 'Ostrzeżenie',
      text: 'Wybrany nieprawidłowy zakres dat',
      action: 'Akceptuj'
    }
  },
  filters: {
    name: 'Imię'
  },
  scenario: {
    noDataReceived: 'Nie otrzymano danych',
    filterPart: {
      name: 'Imię',
      clear: 'Wyczyść',
      notFound: 'Nie znaleziono',
      search: 'Szukaj...'
    }
  },
  header: {
    logout: 'Wyloguj',
    changePassword: 'Zmień hasło',
    settings: 'Ustawienia',
    title: 'Wyświetl interfejs użytkownika',
    searchTitle: 'Wyszukaj użytkownika',
    input: 'Zacznij wpisywać imię lub login...',
    cancel: 'Anuluj',
    view: 'Widok',
    id: 'ID',
    login: 'Login',
    name: 'Imię',
    phone: 'Telefon',
    email: 'E-mail',
    stopSettings: 'Zatrzymaj przegląd'
  },
  directTask: {
    addCase: 'Dodaj przypadku',
    commentCase: 'SkomentujPrzypadek',
    actions: 'Akcje',
    searchProduct: 'PLU/Kod kreskowy',
    searchProductShort: 'PLU/Kod kreskowy',
    photo: 'Zdjęcie',
    back: 'Powrót',
    treeEmpty: 'AKCJE NIEDOSTĘPNE',
    step: 'Krok',
    sortingButton: 'Sortowanie',
    sortButton: 'Sortuj',
    add: 'Dodaj',
    delete: 'Usuń',
    deleteManualCases: 'Usuń przypadki ręczne',
    show: 'Pokaż',
    hide: 'Ukryj',
    comment: 'Komentarz',
    showDoneCases: 'Pokaż ukończone zadania',
    hideDoneCases: 'Ukryj ukończone zadania'
  },
  gridTable: {
    pluList: 'Lista PLU'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Załaduj zdjęcie',
    plu: 'Nazwa/PLU',
    success: 'Przypadek utworzony',
    delete: 'Usuwać',
    add: 'Tworzyć',
    new: 'Nowy',
    cancel: 'Anulować',
    comment: 'Komentarz'
  },
  pagination: {
    apply: 'Przejdź',
    placeholder: 'Strona'
  },
  restorePass: {
    noLength: 'Hasło musi mieć co najmniej 12 znaków',
    noLowercase: 'Hasło musi zawierać co najmniej jedną małą literę',
    noUppercase: 'Hasło musi zawierać co najmniej jedną wielką literę',
    noNumber: 'Hasło musi zawierać co najmniej jedną cyfrę',
    noMatch: 'Hasła nie są zgodne',
    password: 'Hasło',
    repeatPassword: 'Powtórz hasło',
    ok: 'OK',
    cancel: 'Anuluj'
  },
  navigation: {
    missions: 'Zadania',
    scenarios: 'Scenariusze'
  },
  changePassword: {
    previousPassword: 'Obecne hasło',
    newPassword: 'Nowe hasło',
    confirmPassword: 'Powtórz nowe hasło',
    apply: 'Zastosuj',
    title: 'Zmień hasło',
    success: 'Hasło zostało pomyślnie zmienione',
    wrongPassword: 'Nieprawidłowe hasło',
    weakPassword: 'Hasło nie spełnia wymagań',
    passwordsNotMatch: 'Hasła nie są zgodne',
    requirements: 'Nowe hasło powinno mieć co najmniej 12 znaków, jedną dużą i małą literę oraz co najmniej jedną cyfrę'
  },
  auth: {
    login: 'Login',
    password: 'Hasło',
    restorePass: 'Przywróć hasło',
    submit: 'Prześlij',
    loginError: 'Nieprawidłowy login i/lub hasło'
  },
  restorePassForm: {
    restore: 'Przywróć',
    cancel: 'Anuluj',
    info: 'Proszę podać swój adres e-mail, który jest zarejestrowany w GROSA',
    emailInvalid: 'Podany adres e-mail nie został znaleziony',
    email: 'E-mail',
    success: 'Link do przywracania został wysłany na Twój adres e-mail'
  },
  search: {
    notFound: 'Nie znaleziono'
  },
  noMatchPage: {
    title: 'Żądana strona nie została znaleziona',
    body: 'Wróć do strony głównej'
  },
  warnings: {
    title: 'Ostrzeżenie',
    isDesktop: 'Wgrywanie zdjęć jest dostępne tylko w wersji mobilnej systemu. Proszę użyj aparatu w telefonie lub tablecie.'
  },
  errors: {
    netErrorText: 'Błąd sieci. Proszę odświeżyć stronę',
    netErrorReload: 'Odśwież',
    netErrorTitle: 'Błąd',
    netErrorClose: 'Zamknij',
    noShops: 'Ups! Wygląda na to, że nie przypisano Ci jeszcze żadnego sklepu. Powiedz o tym swojemu menadżerowi',
    noCases: 'Wygląda na to, że nasz serwis nie znalazł ani jednej obudowy w tym sklepie. Zacznij je tworzyć samodzielnie',
    noAutoOrder: 'Nie ma zadań umożliwiających dostosowanie kolejności automatycznej',
    causeError: 'Błąd',
    scenariosEmpty: 'Tablica scenariuszy jest pusta'
  },
  officeUI: {
    resetZoom: 'Resetuj zoom'
  },
  gridAutocomplete: {
    placeholder: 'Zacznij wpisywać nazwę sklepu',
    notFound: 'Sklep nie znaleziony'
  },
  targets: {
    nothing: '',
    shop: 'Sklep',
    category: 'Kategoria',
    supplier: 'Dostawca',
    vendor: 'Dostawca',
    brand: 'Marka',
    plu: 'PLU',
    day: 'Dzień',
    week: 'Tydzień',
    month: 'Miesiąc',
    quarter: 'Kwartał',
    year: 'Rok'
  },
  bubbleChart: {
    qty: 'ilość',
    currency: 'PLN'
  },
  chart: {
    quarter: 'kwartał',
    week: 'tydzień'
  },
  download: {
    CSV: 'Pobierz CSV',
    XLS: 'Pobierz XLS'
  },
  counterTask: {
    task: 'Oczekujące zadania',
    noTask: 'Brak zadań'
  },
  filtersShop: {
    shopName: 'Adres sklepu',
    shopId: 'Nazwa sklepu'
  },
  chartCaption: {
    thousand: 'tys.',
    million: 'mln',
    billion: 'mld'
  },
  common: {
    close: 'Zamknij',
    userNotFound: 'Użytkownik nie znaleziony',
    yes: 'Tak',
    no: 'Nie'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Wewnętrzne PLU',
      shop_id: 'ID sklepu',
      ass_type: 'Typ asortymentu',
      category_id3: 'ID sekcji',
      category_id4: 'ID kategorii',
      stock: 'Wolumen pozostałości',
      stock_sum: 'Suma bilansu',
      product_turnover: 'Obrót',
      days_without_sales: 'Dni bez sprzedaży',
      last_sales_date: 'Data ostatniej sprzedaży',
      last_sales_volume: 'Ostatni wolumen sprzedaży',
      last_shipment_date: 'Ostatni termin dostawy',
      last_shipment_volume: 'Ostatnia wielkość dostawy',
      promo_start: 'Początek promocji',
      promo_end: 'Koniec promocji',
      promo_period: 'Okres promocji',
      is_promo: 'Promocja',
      title: 'Nazwa',
      category_name3: 'Sekcja',
      category_name4: 'Kategoria'
    },
    hideReadyTasks: 'Ukryj gotowe zadania',
    nothingFound: 'Nic nie znaleziono',
    shopsNotFound: 'Nie znaleziono sklepów',
    chooseShop: 'Wybierz sklep',
    createTasks: 'Utwórz zadania',
    taskCreating: 'Tworzenie zadań',
    next: 'Następny',
    columns: 'Kolumny',
    from: 'Od',
    ok: 'OK',
    cancel: 'Anuluj',
    filter: 'Filtruj',
    chooseValue: 'Wybierz wartość',
    enterValue: 'Wprowadź wartość',
    rangeFrom: 'Od',
    rangeTo: 'Do',
    selected: 'Wybrane',
    foundRows: 'Znaleziono wierszy',
    createdTasksTotal: 'Łącznie utworzonych zadań'
  }
};
